/**
 * endpoints documentation:
 * https://vb-back.careerpathway.info/docs#/Opportunities/get_search_opportunities_endpoint_opportunities_search_get
 * ASSUME all URLS are GET, unless otherwise noted
*/
const BASE_URL = process.env.REACT_APP_API_BASE_URL

export const API_PATHS = {

  // ALL opportunities - GET & POST
  // !! Added for Swagger parity and DEV use, should NOT be used for production: this WILL NOT scale
  OPPORTUNITIES: `${BASE_URL}/opportunities`,

  // PAGINATED opportunities
  SEARCH_OPPORTUNITIES: `${BASE_URL}/opportunities`,

  // GET a specific Opportunity, *must pass args*
  OPPORTUNITY: (company_id, opportunity_id) => {
    return (`${BASE_URL}/companies/${company_id}/opportunities/${opportunity_id}`)
  },

  // get THREE newest Opportunities
  NEWEST_OPPORTUNITIES: `${BASE_URL}/opportunities/newest`,

  // Filter Opportunities by Skils and Causes - POST ONLY
  SEARCH_OPPORTUNITIES_BY_SKILLS_CAUSES: `${BASE_URL}/opportunities/skills_and_causes`,

  // Create Company - POST ONLY
  CREATE_COMPANY: `${BASE_URL}/companies`,
  // Create Volunteer - POST ONLY
  CREATE_VOLUNTEER: `${BASE_URL}/volunteer`,

  // Testimonial - POST & GET ALL
  TESTIMONIALS: `${BASE_URL}/testimonials`,

  // Favorites - POST - toggle an opportunity a favorite
  TOGGLE_FAVORITE: (volunteer_id, opportunity_id) => {
    return `${BASE_URL}/favorites/${volunteer_id}/${opportunity_id}/toggle`
  },

  // Skills - GET/POST -
  SKILLS: `${BASE_URL}/skills`,

  // Causes - GET/POST - List of Causes
  CAUSES: `${BASE_URL}/causes`,

  // Statistics - GET/POST - stats on volunteers, languages, Projects used on Home Page
  STATS: `${BASE_URL}/statistics`,

}