export default function Infographics() {
  return (
    <div style={{ backgroundColor: "#F7F8FA", width: "100vw", borderBottom:"3px #634E8B solid" }}>
      <h4 style={{fontWeight:'bold',textAlign:'center', margin:'50px'}}>What's in it for me?</h4>
      <div style={{display:'flex', justifyContent:'center', marginBottom:'3%'}}>
        <img style={{height:'500px'}} alt="benefits-infographic" src="/benefits-infographic.png" />
      </div>
    </div>
  );
}
