export const Infographic1 = () => {
  return (
    <svg width="339" height="289" viewBox="0 0 339 289" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1660_1491)">
        <path d="M200.481 32.5722C200.45 32.7144 200.413 32.8666 200.357 33.029C198.435 34.445 196.782 35.4499 196.166 34.6733C191.857 36.881 193.984 38.5914 196.241 40.2459C197.503 41.1645 198.802 42.0729 199.039 43.0372C199.611 45.3819 198.983 47.8179 197.975 50.0561C195.694 55.1109 191.422 59.5516 185.945 62.5561C189.109 58.9071 196.981 52.7662 192.299 50.4468C190.036 49.3252 173.416 49.9343 172.036 48.7721C164.712 42.6159 183.109 34.45 182.45 31.6029C182.432 31.4963 182.401 31.3999 182.363 31.3035C180.044 25.2488 186.423 24.3658 191.36 19.4632C191.478 19.3465 191.596 19.2348 191.721 19.1282C193.132 17.8848 194.923 17.1692 196.918 16.814C200.033 19.4632 200.064 24.1678 198.659 25.5889C200.189 27.8372 200.954 30.2326 200.481 32.5722Z" fill="#2F2E41"/>
        <path d="M250.868 191.831H246.949V288.588H250.868V191.831Z" fill="#312C47"/>
        <path d="M263.524 0.666455C260.7 -1.0052 255.802 0.576204 252.584 4.19875C251.175 5.78445 250.322 7.49841 250.045 9.06466L248.226 11.167L222.177 38.8311C222.177 38.8311 193.447 57.0322 191.005 61.1888C188.563 65.3453 191.607 69.0439 191.607 69.0439L197.372 71.247L236.452 42.4123L255.272 17.1668L257.934 13.7344C259.671 13.0326 261.402 11.8381 262.811 10.2523C266.029 6.62985 266.348 2.33811 263.524 0.666455Z" fill="#A0616A"/>
        <path d="M151.659 98.2295L153.353 123.47H201.198L200.102 98.2295H151.659Z" fill="#A0616A"/>
        <path d="M137.809 170.008L148.706 226.613L138.131 269.131H150.706L169.353 223.874L168.731 172.112L137.809 170.008Z" fill="#A0616A"/>
        <path d="M207.397 170.008L186.138 280.437H175.173L175.858 223.403L176.474 172.112L207.397 170.008Z" fill="#A0616A"/>
        <path d="M201.264 119.263C183.387 124.836 166.624 125.042 152.303 113.479C152.839 101.295 148.958 92.6 144.251 84.5572L143.929 59.8423L162.289 50.377H191.697L191.757 50.4054C195.264 52.0783 199.514 52.336 203.252 51.0373C203.583 50.9221 203.8 50.8676 203.841 50.9028C209.382 55.6519 206.169 65.671 205.139 72.3949L202.874 87.1865C200.61 92.9883 200.161 113.154 201.264 119.263Z" fill="#FFA400"/>
        <path d="M152.304 113.479L148.438 126.1C148.438 126.1 129.112 152.918 138.775 180.263L169.804 188.65L171.952 164.224L175.495 188.65C175.495 188.65 199.653 185.784 208.028 178.948C208.028 178.948 214.47 143.19 205.451 128.992L201.264 117.686C201.264 117.686 174.529 123.734 152.304 113.479Z" fill="#2F2E41"/>
        <path d="M194.031 60.2512C194.031 60.2512 198.756 69.1162 205.09 66.8043C218.467 64.5921 236.451 42.412 236.451 42.412L218.013 41.4375L194.822 56.6872C193.974 57.4864 193.542 59.272 194.031 60.2512Z" fill="#FFA400"/>
        <path d="M212.677 282.92C213.025 283.398 213.208 284.94 213.208 285.495C213.208 287.203 211.512 288.587 209.42 288.587H174.859C173.431 288.587 172.275 287.643 172.275 286.478V285.304C172.275 285.304 170.565 281.773 174.085 277.422C174.085 277.422 178.46 280.829 184.997 275.493L186.925 272.643L200.879 280.973L208.614 281.75C210.306 281.92 211.807 281.724 212.677 282.92Z" fill="#FFA400"/>
        <path d="M171.286 283.948C171.375 284.499 170.8 285.975 170.532 286.486C169.709 288.055 167.483 288.783 165.559 288.111L133.788 277.006C132.476 276.548 131.868 275.308 132.429 274.237L132.996 273.158C132.996 273.158 133.126 269.363 138.46 266.494C138.46 266.494 140.839 271.032 149.422 268.227L152.569 266.226L161.38 278.367L168.115 281.567C169.589 282.267 171.063 282.568 171.286 283.948Z" fill="#FFA400"/>
        <path d="M140.952 152.573C144.441 152.579 147.279 148.957 147.291 144.482C147.296 142.523 146.759 140.726 145.861 139.323L145.829 136.75L147.118 101.874C147.118 101.874 157.365 73.3914 156.365 68.8546C155.365 64.3178 150.245 62.7725 150.245 62.7725L143.991 63.7399L132.975 105.789L135.814 135.251L136.116 139.305C135.21 140.705 134.663 142.5 134.658 144.459C134.646 148.933 137.464 152.566 140.952 152.573Z" fill="#A0616A"/>
        <path d="M155.525 57.2133C155.525 57.2133 143.285 56.6875 140.708 61.946C138.131 67.2045 136.069 93.9786 136.069 93.9786L153.592 84.5576C157.365 76.4484 160.38 68.103 157.457 57.9071L155.525 57.2133Z" fill="#FFA400"/>
        <path d="M200.481 32.572C200.45 32.7142 200.413 32.8664 200.357 33.0288C200.207 33.5262 199.946 34.1098 199.611 34.7544C198.535 36.825 197.434 38.6572 196.241 40.2457C193.704 43.6308 190.776 45.9095 186.883 47.0108C183.781 47.8939 180.063 48.0258 175.443 47.3711L174.523 47.2392L168.138 46.3358C166.745 42.5954 163.332 38.6216 160.932 31.0445C159.918 27.8573 161.858 27.0909 162.219 23.8124C163.244 14.4589 168.927 11.7234 175.767 15.7835C178.683 13.4033 185.839 15.3166 191.721 19.128C194.518 20.9348 197.024 23.178 198.659 25.5887C200.189 27.837 200.954 30.2324 200.481 32.572Z" fill="#2F2E41"/>
        <path d="M181.636 45.6484C190.842 45.6484 198.304 39.557 198.304 32.043C198.304 24.5289 190.842 18.4375 181.636 18.4375C172.431 18.4375 164.968 24.5289 164.968 32.043C164.968 39.557 172.431 45.6484 181.636 45.6484Z" fill="#A0616A"/>
        <path d="M161.498 9.74232C166.596 10.3419 170.133 14.2014 169.399 18.3628C168.664 22.5242 163.936 25.4117 158.838 24.8121C153.74 24.2125 149.057 17.6957 154.014 16.5535C159.907 15.1955 156.4 9.14275 161.498 9.74232Z" fill="#2F2E41"/>
        <path d="M0.666992 288.374C0.666992 288.721 1.00845 289 1.43365 289H337.9C338.326 289 338.667 288.721 338.667 288.374C338.667 288.027 338.326 287.749 337.9 287.749H1.43365C1.00845 287.749 0.666992 288.027 0.666992 288.374Z" fill="#CCCCCC"/>
        <path d="M96.3113 191.831H92.3916V288.588H96.3113V191.831Z" fill="#312C47"/>
        <path d="M94.4043 190.779C95.4555 190.779 96.3076 191.475 96.3076 192.333V232.727C96.3076 233.585 95.4555 234.281 94.4043 234.281C93.3531 234.281 92.501 233.585 92.501 232.727V192.333C92.501 191.475 93.3531 190.779 94.4043 190.779ZM103.921 212.53C103.921 211.672 104.773 210.976 105.824 210.976H127.876L119.703 204.308C118.959 203.701 118.959 202.716 119.703 202.108C120.448 201.501 121.654 201.501 122.398 202.108L133.818 211.43C134.563 212.036 134.564 213.02 133.822 213.627L133.818 213.63L122.398 222.952C121.654 223.559 120.448 223.559 119.703 222.952C118.959 222.344 118.959 221.359 119.703 220.752L127.876 214.084H105.824C104.773 214.084 103.921 213.388 103.921 212.53Z" fill="#3F3D56"/>
        <path d="M250.759 192.333V232.727C250.759 233.585 249.907 234.281 248.856 234.281C247.804 234.281 246.952 233.585 246.952 232.727V192.333C246.952 191.475 247.804 190.779 248.856 190.779C249.907 190.779 250.759 191.475 250.759 192.333ZM237.436 214.084H215.384L223.557 220.752C224.301 221.359 224.301 222.344 223.557 222.952C222.812 223.559 221.606 223.559 220.861 222.952L209.441 213.63L209.438 213.627C208.696 213.02 208.697 212.036 209.441 211.43L220.861 202.108C221.606 201.501 222.812 201.501 223.557 202.108C224.301 202.716 224.301 203.701 223.557 204.308L215.384 210.976H237.436C238.487 210.976 239.339 211.672 239.339 212.53C239.339 213.388 238.487 214.084 237.436 214.084Z" fill="#3F3D56"/>
        <path d="M47.5705 165.643H69.6159C70.1717 165.643 70.6237 165.273 70.6237 164.82C70.6237 164.366 70.1717 163.997 69.6159 163.997H47.5705C47.0147 163.997 46.5625 164.366 46.5625 164.82C46.5625 165.273 47.0147 165.643 47.5705 165.643Z" fill="#F2F2F2"/>
        <path d="M69.6159 181.542H47.5705C47.0147 181.542 46.5625 181.91 46.5625 182.364C46.5625 182.818 47.0147 183.187 47.5705 183.187H69.6159C70.1717 183.187 70.6237 182.818 70.6237 182.364C70.6237 181.91 70.1717 181.542 69.6159 181.542Z" fill="#F2F2F2"/>
        <path d="M58.6642 205.73H36.6188C36.0631 205.73 35.6108 206.099 35.6108 206.553C35.6108 207.007 36.0631 207.376 36.6188 207.376H58.6642C59.22 207.376 59.672 207.007 59.672 206.553C59.672 206.099 59.22 205.73 58.6642 205.73Z" fill="#F2F2F2"/>
        <path d="M81.4459 172.775H35.7409C35.1851 172.775 34.7329 173.144 34.7329 173.598C34.7329 174.051 35.1851 174.42 35.7409 174.42H81.4459C82.0017 174.42 82.4537 174.051 82.4537 173.598C82.4537 173.144 82.0017 172.775 81.4459 172.775Z" fill="#F2F2F2"/>
        <path d="M282.709 165.643H304.754C305.31 165.643 305.762 165.273 305.762 164.82C305.762 164.366 305.31 163.997 304.754 163.997H282.709C282.153 163.997 281.701 164.366 281.701 164.82C281.701 165.273 282.153 165.643 282.709 165.643Z" fill="#F2F2F2"/>
        <path d="M304.754 181.542H282.709C282.153 181.542 281.701 181.91 281.701 182.364C281.701 182.818 282.153 183.187 282.709 183.187H304.754C305.31 183.187 305.762 182.818 305.762 182.364C305.762 181.91 305.31 181.542 304.754 181.542Z" fill="#F2F2F2"/>
        <path d="M293.802 205.73H271.757C271.201 205.73 270.749 206.099 270.749 206.553C270.749 207.007 271.201 207.376 271.757 207.376H293.802C294.358 207.376 294.81 207.007 294.81 206.553C294.81 206.099 294.358 205.73 293.802 205.73Z" fill="#F2F2F2"/>
        <path d="M316.584 172.775H270.879C270.323 172.775 269.871 173.144 269.871 173.598C269.871 174.051 270.323 174.42 270.879 174.42H316.584C317.14 174.42 317.592 174.051 317.592 173.598C317.592 173.144 317.14 172.775 316.584 172.775Z" fill="#F2F2F2"/>
        <path d="M198.659 25.5886C198.51 25.7459 198.336 25.8626 198.149 25.9337C196.265 26.629 195.308 23.0561 194.419 23.3961C190.875 24.7563 187.256 28.862 182.606 31.5163C182.55 31.5468 182.5 31.5772 182.45 31.6026C178.732 33.6936 176.997 33.6073 176.388 33.5464C174.914 33.3992 173.534 34.4595 172.036 35.5765C170.52 36.7069 169.909 40.4029 171.414 41.1591C173.248 42.0777 173.715 43.8134 174.523 47.2391V47.2492C176.55 55.8414 180.063 53.8976 179.497 54.3544C177.656 55.8363 174.927 53.6084 170.171 54.8619C166.117 55.9277 166.285 58.0288 162.088 59.4295C157.63 60.9216 151.568 60.5106 150.275 58.4145C148.994 56.3337 153.327 54.1057 152.14 50.8018C151.282 48.4064 148.59 48.4216 147.788 46.2342C146.774 43.4683 150.293 41.3012 152.762 37.6065C157.387 30.6891 152.103 27.7557 155.87 21.3661C159.781 14.738 159.633 10.3074 167.324 11.2159C169.289 11.4493 172.274 9.88663 173.563 11.4642C173.92 11.9017 172.918 13.0417 176.784 14.0934C183.226 11.4642 196.166 16.1742 196.906 16.7985C196.912 16.8036 196.918 16.8086 196.918 16.8137C200.033 19.4629 200.064 24.1675 198.659 25.5886Z" fill="#2F2E41"/>
      </g>
    <defs>
      <clipPath id="clip0_1660_1491">
        <rect width="338" height="289" fill="white" transform="translate(0.666992)"/>
      </clipPath>
    </defs>
    </svg>
  )
}