/** OpportunityCardSmall documentation
 */
import { Button } from "../sharedComponents/Button";
import { CardDetailTags } from "../sharedComponents/CardDetailTags/CardDetailTags";
import "./OpportunityCardSmall.css";
import { CardTextLink } from "../sharedComponents/CardTextLink/CardTextLink";
import AvatarIcon from "../../icons/AvatarIcon.svg";
import { Typography } from "../sharedComponents/Typography/Typography";

export const OpportunityCardSmall = ({ opportunity }) => {
  const {
    img: imgUrl,
    title: jobTitle,
    details: description,
    duration,
    company: { location, id: companyId },
    id: opportunityId,
  } = opportunity;

  const onApply = () => {
    // TODO: insert function to open ApplicationPopUp
  };

  return (
    <div className="OpportunityCardSmall root">
      <div className="header">
        <img
          src={imgUrl}
          alt={`${jobTitle} in ${location}`}
          onError={(e) => {e.target.src = AvatarIcon}}
        />
        <h5>{jobTitle}</h5>
      </div>

      <div className="content">
        <CardDetailTags
          duration={duration}
          location={location}
          variant="short"
          showLines={false}
        />
        <div className="description">
          <Typography variant="bSm" text={description} color="black" />
        </div>
      </div>

      <div className="buttons">
        <Button variant="outlineDark" onClick={onApply} label="Apply" />
        <CardTextLink
          label="Details"
          href={`/companies/${companyId}/opportunities/${opportunityId}`}
          state={opportunity}
        />
      </div>
    </div>
  );
};
