/** Loader documentation
 */

import "./styles.css";


export const Loader = ({color="purple"}) => {

  return (
    <div className={`root Loader ${color}`} role="status">
      {/**
       * sr-only === "Screen Reader only"
       * hides the text in the loader but allows screen readers to know the status
       * */}
      <span className="sr-only">Loading...</span>
    </div>
  );
};
