/** OpportunitiesPage documentation
 */
import { useState, useEffect } from "react";
import "./styles.css";
import { OpportunityCardMed } from "../OpportunityCardMed/OpportunityCardMed";
import { SearchNavBar } from "../SearchNavBar";
import { API } from "../../api";
import { Typography } from "../sharedComponents/Typography/Typography";
import {Loader} from "../sharedComponents/Loader"

const NoneFound = () => {
  return (
    <div className="status-message">
      <Typography
        text={`Oops! We couldn’t find the volunteer opportunities that match the criteria.`}
        variant="hMd"
        color="dark-purple"
      />
      <Typography
        text={`Please check back later for more opportunities, or explore other available options. Thank you for your interest in making a difference!`}
        variant="bMd"
        color="dark-purple"
      />
    </div>
  );
};

const ErrorMessage = () => {
  return (
    <div className="status-message">
      <Typography
        text={`Oops! Something went wrong.`}
        variant="hMd"
        color="dark-purple"
      />
      <Typography
        text={`Please try reloading the page or come back later. If the problem persists, contact our support team.`}
        variant="bMd"
        color="dark-purple"
      />
    </div>
  );
};

export const OpportunitiesPage = () => {
  const [opportunities, setOpportunities] = useState(undefined);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  // TODO: create state for query string? + hook for appending to it

  // const [page, setPage] = useState({currentPage: undefined, totalPages: undefined})

  useEffect(() => {
    if (opportunities === undefined) {
      const fetchOpportunities = async () => {
        /**
         * TODO:
         * BLOCKED BY: https://careerpathway.atlassian.net/browse/CVB-78
         *
         * check query string for search params, do not use getAll()
         * will depend upon the change of queryString
         */
        const oppsResult = await API.opportunities.getAll();
        try {
          setOpportunities(oppsResult);
        } catch (error) {
          console.error(error);
          setIsError(true);
          setOpportunities([]);
        }
        setIsLoading(false)
      };
      
      fetchOpportunities();
    }
  }, [opportunities]);

  return (
    <div className="OpportunitiesPage root">
      <SearchNavBar setOpportunities={setOpportunities} />

      <div className="search-results">
        {opportunities?.length > 0 ? (
          opportunities.map((opp, index) => {
            return (
              <OpportunityCardMed
                opportunity={opp}
                // alternates pic left and right
                isImageLeft={(index + 1) % 2 !== 0}
                key={`${opp.id}-${opp.title}`}
              />
            );
          })
        ) : (
          isError ? <ErrorMessage /> : <NoneFound />
        )}
        {isLoading ? <Loader /> : null}
      </div>
    </div>
  );
};
