export const GoogleGColoredIcon = () => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.5002 10.2292C20.5002 9.54942 20.444 8.86599 20.324 8.19727H10.7017V12.048H16.2119C15.9833 13.2899 15.2486 14.3885 14.1727 15.0866V17.5852H17.4602C19.3906 15.8435 20.5002 13.2715 20.5002 10.2292Z"
        fill="#4285F4"
      />
      <path
        d="M10.7013 19.9995C13.4526 19.9995 15.7729 19.114 17.4635 17.5855L14.1761 15.087C13.2615 15.6969 12.0807 16.0423 10.705 16.0423C8.04359 16.0423 5.787 14.2823 4.97733 11.916H1.58496V14.4917C3.31676 17.8684 6.84407 19.9995 10.7013 19.9995Z"
        fill="#34A853"
      />
      <path
        d="M4.97381 11.9161C4.54648 10.6741 4.54648 9.32934 4.97381 8.08742V5.51172H1.58518C0.138272 8.33727 0.138272 11.6662 1.58518 14.4918L4.97381 11.9161Z"
        fill="#FBBC04"
      />
      <path
        d="M10.7013 3.95792C12.1557 3.93587 13.5613 4.47232 14.6147 5.45704L17.5272 2.60209C15.683 0.904557 13.2352 -0.0287207 10.7013 0.000673866C6.84408 0.000673866 3.31676 2.13178 1.58496 5.51215L4.97358 8.08785C5.77951 5.71792 8.03984 3.95792 10.7013 3.95792Z"
        fill="#FE2C25"
      />
    </svg>
  );
};
