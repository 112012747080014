/** OpportunityCardLargeSummary documentation
 */
import { Typography } from "../../sharedComponents/Typography";
import "./styles.css";
import { Button } from "../../sharedComponents/Button/Button";
import { CardBadge } from "../../sharedComponents/CardBadge/CardBadge";
import AvatarIcon from "../../../icons/AvatarIcon.svg";

export const OpportunityCardLargeSummary = ({
  companyName,
  imgUrl,
  skills,
  tools,
}) => {
  return (
    <section className="root OpportunityCardLargeSummary">
      <img
        src={imgUrl}
        alt={`${companyName} logo`}
        onError={(e) => {
          e.target.src = AvatarIcon;
        }}
      />
      <div className="details">
        <div className="content">
          <div className="skills">
            <Typography variant="hSm" color="black" text="Skills" />
            {skills?.length &&
              skills.map((s) => {
                return <CardBadge text={s} key={s} />;
              })}
          </div>

          <div className="tools">
            <Typography variant="hSm" color="black" text="Tools" />
            {tools?.length &&
              tools.map((s) => {
                return <CardBadge text={s} key={s} />;
              })}
          </div>
        </div>

        <Button
          className="half"
          label="Apply"
          onClick={() => {
            // TODO: insert function to open ApplicationPopUp
          }}
        />
      </div>
    </section>
  );
};
