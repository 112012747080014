export const EyeIcon = ({ color = "#312C47" }) => {
  const [width, height] = [20, 16];
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99985 4.66667C8.1589 4.66667 6.66652 6.15905 6.66652 8C6.66652 9.84095 8.1589 11.3333 9.99985 11.3333C11.8408 11.3333 13.3332 9.84095 13.3332 8C13.3332 6.15905 11.8408 4.66667 9.99985 4.66667Z
        M8.33319 8C8.33319 7.07953 9.07938 6.33333 9.99985 6.33333C10.9203 6.33333 11.6665 7.07953 11.6665 8C11.6665 8.92047 10.9203 9.66667 9.99985 9.66667C9.07938 9.66667 8.33319 8.92047 8.33319 8Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.9116 7.62673C19.9117 7.62704 19.9119 7.62732 19.1665 8C19.9119 8.37268 19.9117 8.37296 19.9116 8.37326L19.9102 8.37594L19.9074 8.38163L19.8979 8.40015C19.89 8.41561 19.8787 8.43724 19.8642 8.46463C19.8352 8.51942 19.7932 8.59731 19.7382 8.69501C19.6284 8.8903 19.4666 9.1654 19.2546 9.49377C18.8315 10.1489 18.2025 11.0245 17.3786 11.9033C15.7461 13.6446 13.2494 15.5 9.99985 15.5C6.75026 15.5 4.25359 13.6446 2.62107 11.9033C1.79722 11.0245 1.16824 10.1489 0.745133 9.49377C0.533062 9.1654 0.371347 8.8903 0.261496 8.69501C0.20654 8.59731 0.16447 8.51942 0.135481 8.46463C0.120984 8.43724 0.109749 8.41561 0.101802 8.40015L0.0923451 8.38163L0.0894692 8.37594L0.0884952 8.374C0.088342 8.3737 0.0878308 8.37268 0.833187 8C0.0878308 7.62732 0.0879711 7.62704 0.0881242 7.62673L0.0894692 7.62406L0.0923451 7.61837L0.101802 7.59985C0.109749 7.58439 0.120984 7.56276 0.135481 7.53537C0.16447 7.48058 0.20654 7.40269 0.261496 7.30499C0.371347 7.1097 0.533062 6.8346 0.745133 6.50623C1.16824 5.85109 1.79722 4.9755 2.62107 4.09672C4.25359 2.35537 6.75026 0.5 9.99985 0.5C13.2494 0.5 15.7461 2.35537 17.3786 4.09672C18.2025 4.9755 18.8315 5.85109 19.2546 6.50623C19.4666 6.8346 19.6284 7.1097 19.7382 7.30499C19.7932 7.40269 19.8352 7.48058 19.8642 7.53537C19.8787 7.56276 19.89 7.58439 19.8979 7.59985L19.9074 7.61837L19.9102 7.62406L19.9116 7.62673Z
        M2.1452 8.58956C1.99562 8.35796 1.87488 8.15739 1.7838 8C1.87488 7.84261 1.99562 7.64204 2.1452 7.41044C2.52938 6.81557 3.09832 6.0245 3.83697 5.23662C5.32945 3.64463 7.41611 2.16667 9.99985 2.16667C12.5836 2.16667 14.6703 3.64463 16.1627 5.23662C16.9014 6.0245 17.4703 6.81557 17.8545 7.41044C18.0041 7.64204 18.1248 7.84261 18.2159 8C18.1248 8.15739 18.0041 8.35796 17.8545 8.58956C17.4703 9.18443 16.9014 9.9755 16.1627 10.7634C14.6703 12.3554 12.5836 13.8333 9.99985 13.8333C7.41611 13.8333 5.32945 12.3554 3.83697 10.7634C3.09832 9.9755 2.52938 9.18443 2.1452 8.58956Z"
        fill={color}
      />

      <path
        d="M19.1665 8L19.9116 7.62673C20.0289 7.86134 20.0289 8.13866 19.9116 8.37326L19.1665 8Z"
        fill={color}
      />
      <path
        d="M0.0881242 7.62673L0.833187 8L0.0878308 8.37268C-0.0294724 8.13807 -0.0291789 7.86134 0.0881242 7.62673Z"
        fill={color}
      />
    </svg>
  );
};
