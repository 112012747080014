/**
 * Button documentation
 *
 * We now have a consistent set of code and styling for a Button.
 * Add on as new variants as they come up in Figma.
 *
 *
 * variants:
 *  "default" - orange button with white text. onHover the background becomes purple.
 *  "outlineDark" - outlined button using darker coloring (i.e. works over light backgrounds)
 *
 * className:
 *  allows us to add additional styles as needed for one-off changes. Anything repeated should be an added prop or variant.
 */
import { Typography } from "./../Typography/Typography";
import "./styles.css";

export const VARIANTS = {
  default: "default",
  outlineDark: "outlineDark",
  borderless: "borderless",
  text: "text",
  defaultDark: "defaultDark",
};

export const Button = ({
  label,
  onClick,
  isDisabled = false,
  variant,
  iconLeft,
  className
}) => {
  
  return (
    <button
      className={`Button root ${VARIANTS[variant] ?? VARIANTS.default} ${className ? className : ""}`}
      onClick={onClick}
      aria-label={label}
      disabled={isDisabled}
    >
      {iconLeft && iconLeft}
      <Typography text={label} variant="btnMd" />
    </button>
  );
};
