import React from "react";
import { OpportunityCardSmall } from "../../OpportunityCardSmall/OpportunityCardSmall";
import { Typography } from "../../sharedComponents/Typography";
import "./NewestOpenings.css";
import { API } from "../../../api";
import { useState, useEffect } from "react";
import { ButtonLink } from "../../sharedComponents/Button/ButtonLink/ButtonLink";

export function NewestOpenings() {
  const [newestVolOpp, setNewestVolOpp] = useState(undefined);

  useEffect(() => {
    if (newestVolOpp === undefined) {
      const fetchNewestVolOpp = async () => {
        const data = await API.opportunities.getNewest();
        setNewestVolOpp(data);
      };

      fetchNewestVolOpp();
    }
  }, [newestVolOpp]);

  return (
    <div className="root NewestOpenings">
      <Typography
        text={"Check out these volunteer opportunities"}
        variant="hMd"
        color="white"
      />
      <div className="card-container">
        {newestVolOpp && newestVolOpp?.slice(0, 3).map((opportunity) => {
          return (
            <OpportunityCardSmall
              opportunity={opportunity}
              key={opportunity.id}
            />
          );
        })}
      </div>

      <ButtonLink
        label={"Search for more volunteer opportunities"}
        href={"/opportunities"}
        variant={"default"}
      />
    </div>
  );
}
