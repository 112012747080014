/** OpportunityCardMed documentation
 * // ** NOTES
 * Pic
 *  - can be on left or right
 *  - alt = jobTitle
 *
 *  Buttons
 *  - SaveRibbon
 *  - Apply
 *  -
 */
import "./OpportunityCardMed.css";
import { CardTextLink } from "../sharedComponents/CardTextLink/CardTextLink";
import { CardDetailTags } from "../sharedComponents/CardDetailTags/CardDetailTags";
import { Button } from "../sharedComponents/Button";
import { useState } from "react";
import { LikeRibbon } from "../sharedComponents/LikeRibbon/LikeRibbon";
import AvatarIcon from "../../icons/AvatarIcon.svg";

export const OpportunityCardMed = ({ opportunity, isImageLeft = true }) => {
  const {
    img: imgUrl,
    company: { name: companyName, id: companyId, location},
    // companyName,
    title: jobTitle,
    liked,
    // company_id: companyId,
    // location:{location:cityState},
    job_type: opportunityType,
    details: description,
    id: opportunityId,
    cause,
    duration,
  } = opportunity;

  // * internal state to display LikeRibbon filled/unfilled without
  // * having to make an API call to get most recent data
  const [isLiked, setIsLiked] = useState(liked ?? false);

  return (
    <div
      className={`OpportunityCardMed root image-${isImageLeft ? "left" : "right"}`}
    >
      <img
        src={imgUrl}
        alt={`${companyName}`}
        onError={(e) => {
          e.target.src = AvatarIcon;
        }}
      />

      {/* Content Block */}
      <div className="content">
        <div className="info">
          <div className="header">
            <h3>{jobTitle}</h3>
            <LikeRibbon
              isLiked={isLiked}
              opportunityId={opportunityId}
              setIsLiked={setIsLiked}
            />
          </div>

          <div className="company">
            <CardTextLink label={companyName} href={`/company/${companyId}`} />
            <CardTextLink
              label="View all opportunities"
              href={`/company/${companyId}/opportunities`}
            />
          </div>

          <div className="detail-tags">
            <CardDetailTags
              opportunityType={opportunityType}
              location={location}
              duration={duration}
              cause={cause}
            />
          </div>

          <div className="description">{description}</div>
        </div>

        <div className="button-container">
          <Button
            label="Apply"
            onClick={() => {
              // TODO: insert function to open ApplicationPopUp
            }}
          />

          <CardTextLink
            label="Details"
            href={`/companies/${companyId}/opportunities/${opportunityId}`}
          />
        </div>
      </div>
    </div>
  );
};
