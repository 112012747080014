/** OpportunityCardLarge documentation
 */

import "./OpportunityCardLarge.css";

import { OpportunityCardLargeHeader } from "./OpportunityCardLargeHeader";
import { OpportunityCardLargeSummary } from "./OpportunityCardLargeSummary";
import { OpportunityCardLargePostingBody } from "./OpportunityCardLargePostingBody";

export const OpportunityCardLarge = ({opportunity}) => {
  const {
    company: {
      name,
      id: companyId,
      location,
      linkedin,
      website,
    },
    title: jobTitle,
    job_type,
    cause,
    duration,
    liked,
    details: description,
    id: opportunityId,
    responsibilities,
    requirements,
    img: imgUrl,
    posted,
    skills,
    tools,
    interview_process: interviewProcess,
    fun_facts: funFacts,
    hours,
  } = opportunity;

  return (
    <article className="OpportunityCardLarge root">
      <OpportunityCardLargeHeader
        companyName={name}
        jobTitle={jobTitle}
        companyId={companyId}
        location={location}
        opportunityType={job_type}
        cause={cause}
        duration={duration}
        websiteUrl={website}
        liked={liked}
        linkedInUrl={linkedin}
        opportunityId={opportunityId}
      />

      <OpportunityCardLargeSummary
        companyName={name}
        imgUrl={imgUrl}
        skills={skills}
        tools={tools}
      />

      <OpportunityCardLargePostingBody
        description={description}
        opportunityId={opportunityId}
        responsibilities={responsibilities}
        requirements={requirements}
        posted={posted}
        postedBy={name}
        interviewProcess={interviewProcess}
        funFacts={funFacts}
        postedById={companyId}
        duration={duration}
        hours={hours}
      />
    </article>
  );
};
