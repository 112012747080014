import React from "react";
import { Typography } from "../Typography"
import "./Footer.css";

import CPI_logo_white from "../../../icons/CPI_logo_white.svg"
import {LinkedInIcon} from "../../../icons/LinkedInIcon"
import {InstagramIcon} from "../../../icons/InstagramIcon"
import {FacebookIcon} from "../../../icons/FacebookIcon"
import {YouTubeIcon} from "../../../icons/YouTubeIcon"
import {VolunteerBoardLogoIcon} from "../../../icons/VolunteerBoardLogoIcon"

import { SOCIAL_MEDIA_URLS } from "../../../constants/globals";

const FooterLink = ({ href, text, variant, color="white"}) => {
  return (
    <a href={href} className="FooterLink root">
      <Typography variant={variant} color={color} text={text} htmlTag="span"/>
    </a>
  )
}

export function Footer() {
  const { LINKEDIN,INSTAGRAM,FACEBOOK,YOUTUBE} = SOCIAL_MEDIA_URLS
  return (
    <footer className="Footer root">
      <div className="logo-container">
        <img src={CPI_logo_white} alt="Logo" />
        <VolunteerBoardLogoIcon />
      </div>

      <div className="social-media-links">
        <a href={LINKEDIN} target="_blank" rel="noopener noreferrer">
          <LinkedInIcon color="#FFFFFF" />
        </a>

        <a href={INSTAGRAM} target="_blank" rel="noopener noreferrer">
          <InstagramIcon />
        </a>

        <a href={FACEBOOK} target="_blank" rel="noopener noreferrer">
          <FacebookIcon />
        </a>

        <a href={YOUTUBE} target="_blank" rel="noopener noreferrer">
          <YouTubeIcon />
        </a>
      </div>

      <div className="internal-links">
        <FooterLink variant="bSm" text={"For Volunteers"} href="/" />
        <FooterLink variant="bSm" href="/for-companies" text="For Companies" />
        <FooterLink variant="bSm" href="/about" text="About CPI" />
        <FooterLink variant="bSm" href="/contact" text="Contact" />
      </div>

      <div className="legal-container">
        <Typography
          variant="bXs"
          color="white"
          text={`© ${new Date().getFullYear()} Career Pathway Institute`}
        />

        <FooterLink
          text="Privacy Policy"
          variant={"bXs"}
          href="/policy/privacy"
          color="lt-purple2"
        />
        <FooterLink
          text="Terms & Conditions"
          variant={"bXs"}
          href="/terms"
          color="lt-purple2"
        />
        <FooterLink
          text="Cookie Policy"
          variant={"bXs"}
          href="/policy/cookies"
          color="lt-purple2"
        />
      </div>
    </footer>
  );
}
