/** OpportunityDetailPage documentation
 */
import React, { useEffect, useState } from "react";
import { OpportunityCardLarge } from "./../OpportunityCardLarge";
import "./styles.css";
import { OpportunityCardSmall } from "../OpportunityCardSmall/OpportunityCardSmall";
import { Loader } from "../sharedComponents/Loader";
import { Typography } from "../sharedComponents/Typography";
import { API } from "../../api";
import { ButtonLink } from "../sharedComponents/Button/ButtonLink/ButtonLink";
import { ChevronIcon } from "../../icons/ChevronIcon";

export const ErrorMessage = () => {
  return (
    <div className="ErrorMessage root">
      <Typography
        variant="hMd"
        color="black"
        text={`Oops! We couldn't find the volunteer opportunity you're looking for.`}
      />
      <Typography
        variant="bMd"
        color="black"
        text={`It may have been filled or removed. Please check back later for more opportunities, or explore other available options. Thank you for your interest in making a difference!`}
      />
    </div>
  );
};

export const OpportunityDetailPage = () => {
  // * state
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [opportunity, setOpportunity] = useState(undefined);
  const [relatedOpps, setRelatedOpps] = useState(undefined);

  useEffect(() => {
    if (opportunity) return;

    const getOpps = async () => {
      const paths = window.location.href.split("/");
      const [company_id, opportunity_id] = [paths[4], paths[6]];
      const data = await API.opportunities.getOpportunitiesById({
        company_id,
        opportunity_id,
      });

      const { opportunity: opp, similar_opportunities } = data;
      if (opp) {
        setOpportunity(opp);
        setRelatedOpps(similar_opportunities);
      } else {
        setIsError(true);
      }
      setIsLoading(false);
    };
    getOpps();
  }, [opportunity]);

  return (
    <div className="root OpportunityDetailPage">
      {isLoading && <Loader />}
      {isError && <ErrorMessage />}

      {!isError && !isLoading && opportunity && (
        <>
          <div className="banner">
            <Typography
              variant="hLg"
              text="Upgrade your skills"
              color="white"
            />
          </div>
          <div className="back-link">
            <ButtonLink
              label={"Back to search"}
              href={"/opportunities"}
              variant={"text"}
              className={"orange-text"}
              iconLeft={<ChevronIcon color="#FFA400" />}
            />
          </div>
          <OpportunityCardLarge opportunity={opportunity} />
          {relatedOpps.length && (
            <section className="related-opps">
              <Typography
                variant="hMd"
                text={`Check out ${relatedOpps.length === 1 ? "this volunteer opportunity" : "these volunteer opportunities"}`}
                color="black"
              />
              <div className="opps">
                {/* map up to three related opportunities */}
                {relatedOpps.slice(0, 3).map((opp) => {
                  return (
                    <OpportunityCardSmall opportunity={opp} key={opp.id} />
                  );
                })}
              </div>
              <ButtonLink
                label={"Search for more volunteer opportunities"}
                href={"/opportunities"}
                variant={"defaultDark"}
              />
            </section>
          )}
        </>
      )}
    </div>
  );
};
