export const Infographic3 = () => {
  return (
    <svg width="339" height="289" viewBox="0 0 339 289" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1660_1367)">
        <path d="M76.1418 48.2632L73.5649 59.684L86.4121 62.7416L84.8159 50.5485L76.1418 48.2632Z" fill="#A0616A" />
        <path d="M0.333496 284.675C0.333496 285.034 0.674949 285.322 1.10015 285.322H337.567C337.992 285.322 338.333 285.034 338.333 284.675C338.333 284.316 337.992 284.028 337.567 284.028H1.10015C0.674949 284.028 0.333496 284.316 0.333496 284.675Z" fill="#3F3D56" />
        <path d="M229.698 129.605H97.2032C82.1782 129.605 69.998 139.887 69.998 152.571V261.033C69.998 273.717 82.1782 283.999 97.2031 283.999H229.698C244.723 283.999 256.903 273.717 256.903 261.033V152.571C256.903 139.887 244.723 129.605 229.698 129.605Z" fill="white" />
        <path d="M229.698 284.543H97.2034C81.8473 284.543 69.354 273.996 69.354 261.032V152.571C69.354 139.607 81.8473 129.061 97.2034 129.061H229.698C245.054 129.061 257.548 139.607 257.548 152.571V261.032C257.548 273.996 245.054 284.543 229.698 284.543ZM97.2034 130.148C82.5576 130.148 70.6424 140.207 70.6424 152.571V261.032C70.6424 273.397 82.5576 283.455 97.2034 283.455H229.698C244.344 283.455 256.259 273.397 256.259 261.032V152.571C256.259 140.207 244.344 130.148 229.698 130.148L97.2034 130.148Z" fill="#3F3D56" />
        <path d="M239.296 176.626H186.04C183.781 176.626 181.943 175.075 181.943 173.168C181.943 171.261 183.781 169.709 186.04 169.709H239.296C241.555 169.709 243.392 171.261 243.392 173.168C243.392 175.075 241.555 176.626 239.296 176.626Z" fill="#312C47" />
        <path d="M189.334 203.823H137.567C136.262 203.823 135.2 202.926 135.2 201.824C135.2 200.723 136.262 199.827 137.567 199.827H189.334C190.64 199.827 191.701 200.723 191.701 201.824C191.701 202.926 190.64 203.823 189.334 203.823Z" fill="#312C47" />
        <path d="M116.167 137.032H86.612C86.5016 137.032 86.4121 137.108 86.4121 137.201V140.106C86.4121 140.199 86.5016 140.274 86.612 140.274H116.167C116.277 140.274 116.367 140.199 116.367 140.106V137.201C116.367 137.108 116.277 137.032 116.167 137.032Z" fill="#312C47" />
        <path d="M215.718 140.274C217.415 140.274 218.79 139.113 218.79 137.681C218.79 136.248 217.415 135.087 215.718 135.087C214.021 135.087 212.646 136.248 212.646 137.681C212.646 139.113 214.021 140.274 215.718 140.274Z" fill="#3F3D56" />
        <path d="M224.167 140.274C225.863 140.274 227.239 139.113 227.239 137.681C227.239 136.248 225.863 135.087 224.167 135.087C222.47 135.087 221.094 136.248 221.094 137.681C221.094 139.113 222.47 140.274 224.167 140.274Z" fill="#3F3D56" />
        <path d="M232.615 140.274C234.312 140.274 235.688 139.113 235.688 137.681C235.688 136.248 234.312 135.087 232.615 135.087C230.918 135.087 229.543 136.248 229.543 137.681C229.543 139.113 230.918 140.274 232.615 140.274Z" fill="#3F3D56" />
        <path d="M96.8809 214.519C96.8809 214.878 97.2223 215.166 97.6475 215.166H229.254C229.679 215.166 230.021 214.878 230.021 214.519C230.021 214.16 229.679 213.872 229.254 213.872H97.6475C97.2223 213.872 96.8809 214.16 96.8809 214.519Z" fill="#3F3D56" />
        <path d="M137.018 54.8837C139.82 52.9293 143.286 52.5655 144.759 54.071C146.232 55.5765 145.154 58.3807 142.351 60.3355C141.244 61.1294 139.928 61.6878 138.513 61.9642L126.518 70.1186L122.105 65.285L134.399 57.7594C134.987 56.638 135.885 55.6519 137.018 54.8837Z" fill="#A0616A" />
        <path d="M67.6875 73.2822C67.6814 72.9216 67.6854 69.776 70.444 67.7878C73.6129 65.5039 78.0843 66.4283 79.6526 66.7525C83.2754 67.5015 83.5725 68.8135 86.9747 70.1615C93.3399 72.6836 85.3646 71.3927 89.152 70.584C90.4631 70.304 109.324 69.1998 114.019 66.7604C123.149 62.0161 129.371 59.1392 129.371 59.1392L133.438 66.006C133.438 66.006 127.557 72.9094 119.28 78.0236C110.79 83.2695 106.544 85.8925 100.093 86.9059C99.5374 86.9932 85.8737 88.9627 74.8687 82.0217C72.3585 80.4385 67.7592 77.5377 67.6875 73.2822Z" fill="#FFA400" />
        <path d="M80.3037 55.8179C88.1285 55.8179 94.4717 50.4629 94.4717 43.8572C94.4717 37.2515 88.1285 31.8965 80.3037 31.8965C72.479 31.8965 66.1357 37.2515 66.1357 43.8572C66.1357 50.4629 72.479 55.8179 80.3037 55.8179Z" fill="#A0616A" />
        <path d="M191.262 124.214L176.671 111.605L169.139 118.238L180.01 130.327L190.793 131.368L191.262 124.214Z" fill="#A0616A" />
        <path d="M107.947 100.569C108.8 100.629 111.512 101.088 114.104 99.884C115.874 99.0623 116.781 97.8405 117.087 97.452C120.889 92.6317 135.481 85.115 148.577 84.6525C151.543 84.5477 153.495 84.8378 155.269 85.6186C157.785 86.7257 158.789 88.3116 161.982 92.0267C163.728 94.0589 165.661 95.9734 167.428 97.9925C177.775 109.814 181.698 110.742 181.297 114.532C180.852 118.736 175.481 122.726 171.91 122.265C170.448 122.077 169.882 121.218 167.928 119.481C162.201 114.386 159.831 115.327 155.753 111.425C150.992 106.869 151.615 103.092 149.131 102.946C146.828 102.811 145.849 106.033 140.931 110.314C138.681 112.273 137.078 113.172 132.196 116.031C113.185 127.165 112.882 127.947 109.045 128.835C107.366 129.224 95.4523 131.981 91.2082 127.593C84.5667 120.726 100.257 100.03 107.947 100.569Z" fill="#2F2E41" />
        <path d="M186.801 127.063C187.875 126.656 189.129 126.181 189.533 125.087C189.806 124.347 189.649 123.434 189.084 122.804C188.433 122.076 187.469 121.98 187.508 121.851C187.567 121.655 189.847 121.732 192.35 122.479C192.823 122.62 194.962 123.28 197.684 125.074C199.865 126.511 199.572 126.799 200.939 127.628C205.137 130.172 209.075 128.167 211.346 130.553C212.223 131.474 212.597 132.781 212.228 133.838C211.487 135.961 207.884 136.691 205.915 137.069C201.172 137.977 197.583 137.235 193.835 136.702C181.088 134.889 173.926 136.54 172.967 134.047C172.786 133.576 173.081 133.616 174.064 130.153C175.034 126.736 175.002 125.797 175.828 125.524C177.072 125.112 177.907 126.986 181.039 127.627C183.815 128.195 186.106 127.327 186.801 127.063Z" fill="#2F2E41" />
        <path d="M165.321 165.462L161.635 148.112L150.991 150.03L151.944 165.186L160.174 171.16L165.321 165.462Z" fill="#A0616A" />
        <path d="M112.109 106.402C112.776 106.856 114.715 108.521 117.661 108.753C119.671 108.912 121.234 108.331 121.746 108.155C128.098 105.969 145.172 106.666 156.316 112.492C158.84 113.811 160.262 114.977 161.211 116.464C162.557 118.573 162.333 120.361 162.503 124.948C162.597 127.458 162.922 129.958 163.041 132.467C163.74 147.153 166.369 149.781 163.516 152.727C160.351 155.995 153.253 156.749 150.605 154.676C149.52 153.827 149.623 152.848 149.162 150.484C147.811 143.553 145.225 143.209 144.446 138.047C143.537 132.02 146.565 129.19 144.607 127.892C142.791 126.688 139.837 128.89 132.92 130.101C129.755 130.655 127.832 130.639 121.89 130.691C98.7534 130.891 97.9819 131.395 94.2166 130.311C92.5692 129.837 80.8767 126.47 80.2838 120.827C79.356 111.997 106.105 102.31 112.109 106.402Z" fill="#2F2E41" />
        <path d="M159.735 165.705C160.894 165.877 162.248 166.078 163.31 165.365C164.028 164.882 164.505 164.052 164.457 163.263C164.402 162.352 163.668 161.815 163.786 161.727C163.966 161.593 165.801 162.737 167.376 164.542C167.673 164.883 169.004 166.443 170.063 169.218C170.912 171.441 170.477 171.541 171.058 172.875C172.839 176.97 177.431 177.177 177.723 180.228C177.836 181.406 177.276 182.665 176.268 183.364C174.243 184.77 170.775 183.666 168.895 183.045C164.367 181.548 161.89 179.233 159.143 177.015C149.802 169.472 142.777 167.443 143.642 164.926C143.806 164.45 144.023 164.623 147.14 162.223C150.216 159.855 150.813 159.064 151.679 159.229C152.982 159.478 152.427 161.424 154.592 163.44C156.511 165.226 158.984 165.593 159.735 165.705Z" fill="#2F2E41" />
        <path d="M82.2355 46.1438C82.7871 44.4782 82.0943 43.8712 82.8004 43.1235C82.8004 43.1235 83.0845 42.8227 85.6249 42.0108C84.8754 38.3396 85.4366 37.7188 85.4366 37.7188C86.0833 37.0033 87.379 36.8304 88.261 36.924C90.1265 37.1219 90.4568 38.5455 91.6504 38.5136C93.2994 38.4695 95.2771 35.683 94.6632 33.2678C94.1625 31.2977 92.0034 29.8347 91.4621 30.0885C90.9255 30.3403 88.9937 29.2303 88.2611 29.4527C87.7607 29.6046 85.4057 29.4025 84.8717 29.6117C84.2885 29.8401 82.6185 31.2621 82.4588 31.083C81.3492 29.8378 81.3728 29.4031 80.7291 29.1348C79.5884 28.6593 78.3483 29.5382 76.2099 30.4065C71.8102 32.1931 70.5219 31.1273 68.4896 32.632C67.4631 33.392 66.9126 34.8446 65.8535 37.7188C64.3723 41.7383 63.6318 43.748 64.3471 45.6669C65.0766 47.6239 66.1508 47.198 67.7365 49.9589C69.5015 53.0324 68.8932 54.8188 70.5609 55.5227C71.8499 56.0666 73.9214 55.7204 74.7035 54.7278C76.0426 53.0284 72.5815 50.7296 73.762 48.0514C74.4867 46.4074 76.7221 45.1609 78.0929 45.508C79.6429 45.9005 79.7571 48.2467 80.7291 48.2104C81.5615 48.1793 82.1394 46.4341 82.2355 46.1438Z" fill="#2F2E41" />
        <path d="M71.5023 57.5891C74.4016 54.8447 86.211 57.6852 87.3194 62.04C87.7754 63.8312 86.0923 64.5876 86.5662 66.65C87.2595 69.6665 91.1847 69.4609 94.0982 72.5316C98.2345 76.8911 93.9733 81.1526 98.6174 88.4279C99.6098 89.9826 100.446 91.2929 102.195 92.561C106.208 95.4709 109.943 94.473 111.987 97.1709C113.515 99.1885 112.887 101.675 112.74 102.258C112.137 104.646 110.502 105.228 109.35 107.186C107.434 110.442 109.604 112.833 109.727 116.723C109.881 121.591 106.817 128.339 100.689 130.553C93.8039 133.041 85.5003 128.921 81.4822 124.831C79.0845 122.39 78.3885 120.138 77.3396 117.359C72.6517 104.939 70.0032 106.062 66.4182 96.2171C62.3199 84.9621 61.5143 71.7762 66.4182 67.6037C67.0682 67.0507 68.857 65.7724 69.9959 63.4707C71.5284 60.3734 70.2045 58.8175 71.5023 57.5891Z" fill="#FFA400" />
        <path d="M114.342 121.474C116.59 123.885 116.938 126.818 115.12 128.026C113.303 129.233 110.007 128.257 107.759 125.845C106.845 124.892 106.215 123.768 105.922 122.567L96.5503 112.249L101.393 109.131L110.999 119.194C112.313 119.718 113.459 120.499 114.342 121.474Z" fill="#A0616A" />
        <path d="M80.021 68.0481C80.4379 67.9695 84.0865 67.331 86.955 69.2051C90.25 71.358 90.0904 75.242 90.0344 76.6042C89.9051 79.7511 88.4443 80.2644 87.5753 83.3512C85.9495 89.1262 89.0458 94.4932 90.7564 97.4583C91.3485 98.4847 93.2508 101.625 97.0376 105.007C104.402 111.585 110.973 115.047 110.973 115.047L103.914 120.344C103.914 120.344 92.6694 117.451 85.0501 111.654C77.2344 105.707 65.2151 84.6686 62.7235 79.5436C62.5089 79.1022 65.5484 86.2776 71.3518 75.7662C72.6755 73.3686 75.1009 68.9757 80.021 68.0481Z" fill="#FFA400" />
        <path d="M176.813 229.334C172.326 229.915 169.043 232.34 169.482 234.75C169.92 237.161 173.912 238.644 178.4 238.062C180.197 237.851 181.908 237.279 183.389 236.395L202.364 233.733L201.098 227.401L182.166 229.662C180.438 229.194 178.602 229.081 176.813 229.334Z" fill="#FFB6B6" />
        <path d="M258.579 209.597L251.222 222.777L214.235 233.288L183.543 234.671L182.774 228.447L216.037 222.576L250.169 208.959L258.579 209.597Z" fill="#FFA400" />
        <path d="M190.361 259.642L183.755 273.748L175.207 268.827L180.258 254.066L190.361 259.642Z" fill="#FFB6B6" />
        <path d="M162.773 254.394L145.288 268.499L152.282 274.732L170.156 262.923L162.773 254.394Z" fill="#FFB6B6" />
        <path d="M261.857 208.47L261.663 207.322L257.194 199.941L242.429 203.222L245.149 213.391L261.857 208.47Z" fill="#FFB6B6" />
        <path d="M255.64 257.674L256.094 262.601C256.094 262.601 257.971 283.26 242.817 284.9C227.663 286.54 221.058 288.181 212.121 275.387L198.132 254.394L189.962 262.601L179.093 255.378C179.093 255.378 186.864 229.792 193.081 228.808C193.858 228.685 194.635 228.623 195.4 228.61C200.547 228.519 205.399 230.644 208.478 234.127L226.109 254.066L232.52 251.606L255.64 257.674Z" fill="#2F2E41" />
        <path d="M171.71 273.092L175.207 267.515L185.31 273.42C185.31 273.42 187.641 280.636 184.144 281.62C180.647 282.604 170.933 281.292 170.933 281.292C170.933 281.292 153.059 284.573 152.671 281.62C152.282 278.668 160.83 278.012 160.83 278.012L171.71 273.092Z" fill="#2F2E41" />
        <path d="M209.789 269.155L182.784 254.79L168.99 265.219L159.665 256.034C159.665 256.034 176.761 234.712 181.813 233.728C186.864 232.744 195.024 232.088 195.024 232.088L219.892 251.441L209.789 269.155Z" fill="#2F2E41" />
        <path d="M144.899 267.187L153.836 275.388L152.658 276.239C152.658 276.239 150.339 284.764 144.899 281.88C139.459 278.996 139.071 278.34 139.071 278.34C139.071 278.34 123.528 281.292 121.974 276.044C120.42 270.795 124.694 271.779 124.694 271.779L128.575 273.748L144.899 267.187Z" fill="#2F2E41" />
        <path d="M247.091 206.83C253.958 206.83 259.525 202.131 259.525 196.333C259.525 190.536 253.958 185.836 247.091 185.836C240.224 185.836 234.657 190.536 234.657 196.333C234.657 202.131 240.224 206.83 247.091 206.83Z" fill="#FFB6B6" />
        <path d="M244.177 211.258L262.051 206.666C262.051 206.666 269.045 220.443 267.879 226.676C266.714 232.908 256.094 262.601 256.094 262.601L232.52 251.606C232.52 251.606 232.132 245.045 230.966 242.093C229.8 239.141 226.109 230.12 234.269 222.575C242.428 215.031 244.177 211.258 244.177 211.258Z" fill="#FFA400" />
        <path d="M204.838 280.138C201.929 283.08 201.369 286.732 203.587 288.294C205.806 289.857 209.961 288.738 212.87 285.795C214.052 284.633 214.886 283.248 215.304 281.758L227.442 269.161L221.53 265.122L209.109 277.394C207.446 278.007 205.982 278.948 204.838 280.138Z" fill="#FFB6B6" />
        <path d="M257.887 217.876L262.112 232.004L241.435 259.946L220.326 277.855L213.484 273.064L234.773 250.703L250.909 221.891L257.887 217.876Z" fill="#FFA400" />
        <path d="M235.629 194.529C238.817 194.523 238.998 194.54 239.514 194.529C243.469 194.441 244.504 193.127 246.508 193.217C250.086 193.377 245.364 208.521 243.4 215.523C241.474 222.389 247.983 228.542 248.451 228.972C255.059 235.038 266.665 236.148 268.656 233.564C270.268 231.473 264.555 228.294 265.936 223.723C267.286 219.256 273.52 219.731 275.262 215.523C277.087 211.115 270.955 208.892 267.102 198.137C264.89 191.961 265.281 190.81 263.216 186.984C257.698 176.759 230.649 181.113 233.686 190.593C234.273 192.426 232.71 194.535 235.629 194.529Z" fill="#2F2E41" />
        <path d="M275.333 69.9178H144.654C140.959 69.9178 137.952 67.3795 137.952 64.2598V5.65794C137.952 2.53833 140.959 0 144.654 0H275.333C279.029 0 282.036 2.53832 282.036 5.65794V64.2598C282.036 67.3795 279.029 69.9178 275.333 69.9178Z" fill="white" />
        <path d="M275.333 69.9178H144.654C140.959 69.9178 137.952 67.3795 137.952 64.2598V5.65794C137.952 2.53833 140.959 0 144.654 0H275.333C279.029 0 282.036 2.53832 282.036 5.65794V64.2598C282.036 67.3795 279.029 69.9178 275.333 69.9178ZM144.654 0.944835C141.576 0.944835 139.071 3.05918 139.071 5.65794V64.2598C139.071 66.8586 141.576 68.973 144.654 68.973H275.333C278.412 68.973 280.916 66.8586 280.916 64.2598V5.65794C280.916 3.05918 278.412 0.944835 275.333 0.944835H144.654Z" fill="#3F3D56" />
        <path d="M265.248 7.08645C266.175 7.08645 266.926 6.45193 266.926 5.6692C266.926 4.88648 266.175 4.25195 265.248 4.25195C264.32 4.25195 263.569 4.88648 263.569 5.6692C263.569 6.45193 264.32 7.08645 265.248 7.08645Z" fill="#3F3D56" />
        <path d="M269.724 7.08645C270.651 7.08645 271.403 6.45193 271.403 5.6692C271.403 4.88648 270.651 4.25195 269.724 4.25195C268.797 4.25195 268.045 4.88648 268.045 5.6692C268.045 6.45193 268.797 7.08645 269.724 7.08645Z" fill="#3F3D56" />
        <path d="M274.201 7.08645C275.128 7.08645 275.88 6.45193 275.88 5.6692C275.88 4.88648 275.128 4.25195 274.201 4.25195C273.274 4.25195 272.522 4.88648 272.522 5.6692C272.522 6.45193 273.274 7.08645 274.201 7.08645Z" fill="#3F3D56" />
        <path d="M147.465 28.5811C147.002 28.5811 146.626 28.8989 146.626 29.2897C146.626 29.4802 146.713 29.6564 146.871 29.7865C147.031 29.9245 147.24 29.9983 147.465 29.9983H273.082C273.545 29.9983 273.921 29.6804 273.921 29.2897C273.921 29.0991 273.834 28.9229 273.676 28.7928C273.516 28.6549 273.307 28.5811 273.082 28.5811H147.465Z" fill="#FFA400" />
        <path d="M257.413 28.3452V30.2349H147.465C147.158 30.2349 146.878 30.1309 146.676 29.9561C146.469 29.7861 146.346 29.5499 146.346 29.29C146.346 28.7704 146.85 28.3452 147.465 28.3452H257.413Z" fill="#312C47" />
        <path d="M271.683 24.8018H260.491C259.257 24.8018 258.252 23.9543 258.252 22.9121C258.252 21.87 259.257 21.0225 260.491 21.0225H271.683C272.917 21.0225 273.921 21.87 273.921 22.9121C273.921 23.9543 272.917 24.8018 271.683 24.8018Z" fill="#FFA400" />
        <path d="M198.375 14.8814H148.305C147.071 14.8814 146.066 14.0339 146.066 12.9917C146.066 11.9495 147.071 11.1021 148.305 11.1021H198.375C199.61 11.1021 200.614 11.9495 200.614 12.9917C200.614 14.0339 199.61 14.8814 198.375 14.8814Z" fill="#FFA400" />
        <path d="M147.465 49.8398C147.002 49.8398 146.626 50.1577 146.626 50.5485C146.626 50.739 146.713 50.9152 146.871 51.0453C147.031 51.1833 147.24 51.2571 147.465 51.2571H273.082C273.545 51.2571 273.921 50.9392 273.921 50.5485C273.921 50.3579 273.834 50.1817 273.676 50.0516C273.516 49.9137 273.307 49.8398 273.082 49.8398H147.465Z" fill="#FFA400" />
        <path d="M214.324 49.604V51.4937H147.465C147.158 51.4937 146.878 51.3897 146.676 51.2149C146.469 51.0449 146.346 50.8087 146.346 50.5488C146.346 50.0292 146.85 49.604 147.465 49.604H214.324Z" fill="#312C47" />
        <path d="M271.683 46.0606H260.491C259.257 46.0606 258.252 45.2131 258.252 44.1709C258.252 43.1287 259.257 42.2812 260.491 42.2812H271.683C272.917 42.2812 273.921 43.1287 273.921 44.1709C273.921 45.2131 272.917 46.0606 271.683 46.0606Z" fill="#FFA400" />
      </g>
      <defs>
        <clipPath id="clip0_1660_1367">
          <rect width="338" height="289" fill="white" transform="translate(0.333496)" />
        </clipPath>
      </defs>
    </svg>)
}