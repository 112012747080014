import { ApiHelper } from "./constants"
import { API_PATHS } from "./paths"

const getNewest = async () => {
  const res = await ApiHelper(API_PATHS.NEWEST_OPPORTUNITIES)
  return res
}

const getAll = async () => {
  const res = await ApiHelper(API_PATHS.OPPORTUNITIES)
  return res
}

const getOpportunitiesByCauses = async ({ skill_ids, cause_ids }) => {
  // ex: {skill_ids: undefined, cause_ids: [-1,0,1,2]}
  // filter nulls/undef, then loop thru value if array and create params
  const data = {skill_ids, cause_ids }
  let path = API_PATHS.SEARCH_OPPORTUNITIES_BY_SKILLS_CAUSES;

  if ((data.skill_ids && data.skill_ids.length > 1) || (data.cause_ids && data.cause_ids.length > 1)) {
    const entries = Object.entries(data).filter(d=>d[1]).map((entry)=> {
      if (Array.isArray(entry[1])){
        return entry[1].map((list_element)=> `${entry[0]}=${list_element}`)
      }
      return entry[1]
    })
    // entries = [['cause_ids=0', 'cause_ids=1', 'cause_ids=2']] => cause_ids=0&cause_ids=1&cause_ids=2
    path = `${API_PATHS.SEARCH_OPPORTUNITIES_BY_SKILLS_CAUSES}?${entries.flat().join('&')}`
  }

  const res = await ApiHelper(path, {}, "POST")
  return res
}
const getOpportunitiesById = async ({company_id, opportunity_id}) => {
  const res = await ApiHelper(`${API_PATHS.OPPORTUNITY(company_id, opportunity_id)}`)
  return res
}

export const opportunities = {
  getNewest,
  getAll,
  getOpportunitiesByCauses,
  getOpportunitiesById
}