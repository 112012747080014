/** TestimonialCard documentation
 */
import { useState } from "react";
import { Typography } from "../../../sharedComponents/Typography"
import "./styles.css";


export const TestimonialCard = ({ name, testimonial }) => {

  const [show, setShow] = useState(false)
  const toggleShow = () => {
    setShow(!show)
  }
  
  return (
    <article className="TestimonialCard root">
      <Typography
        variant="btnMd"
        text={name}
        color="purple-header"
        htmlTag={"h3"}
      />
      <div onClick={toggleShow}>
        {show ? (
            <Typography
              variant="bSm"
              text={testimonial}
              color="purple-header"
              className={"text"}
            />

        ) : (
          <div className='testimonial-quote-container'>
            <Typography
              variant="bSm"
              text={testimonial}
              color="purple-header"
              className={"testimonial-quote "}
            />
          </div>

        )}
      </div>

    </article>);
};
