/**
 * Though it is easy enough to use localStorage, this utility allows us to have
 * consistent error logging and will ensure JSON.stringify() is not forgotten.
 *
 */

export const LocalStorageService = () => {
  const add = (key, value) => {
    try {
      localStorage.setItem(key, JSON.stringify(value));
    } catch (e) {
      console.error({ error: JSON.stringify(e, Object.getOwnPropertyNames(e)), message: `Error when adding key ${key} to localStorage` });
    }
  }

  const get = (key) => {
    try {
      return JSON.parse(localStorage.getItem(key)) ?? undefined;
    } catch (e) {
      console.error({ error: JSON.stringify(e, Object.getOwnPropertyNames(e)), message: `Error when getting key ${key} from localStorage` });
    }
  }

  const remove = (key) => {
    localStorage.removeItem(key);
    console.log(`Removed ${key} from localStorage`)
  }

  return { get, add, remove }
}
