/** SignUpLogInFooter documentation
 */
import { Typography } from "../../sharedComponents/Typography/Typography";
import { Button } from "../../sharedComponents/Button/Button";
import { GoogleGColoredIcon } from "../../../icons/GoogleGColoredIcon";
import { LinkedInIcon } from "../../../icons/LinkedInIcon";
import { LOGIN_SIGN_UP_MODAL_MODES } from "../../LoginSignUpModal";

import "./styles.css";

export const SignUpLogInFooter = ({ isLogin, setModalMode }) => {
  return (
    <div className="root SignUpLogInFooter">
      <div className="or-separator">
        <div className="line"></div>
        <Typography variant="bSm" color="lt-purple1" text="or" />
        <div className="line"></div>
      </div>

      <div className="button-container">
        <Button
          variant="borderless"
          iconLeft={<GoogleGColoredIcon />}
          label="Google"
        />
        <Button
          variant="borderless"
          iconLeft={<LinkedInIcon color="#312C47" />}
          label="LinkedIn"
        />
      </div>
      <div className="legal">
        <span>
          <Typography
            variant="bXs"
            color="purple-header"
            text="Protected by reCAPTCHA and subject to the Rhombus "
          />
          <a href="/policy/privacy">
            <Typography variant="bXs" color="grey" text="Privacy" />
          </a>
        </span>
        <span>
          <a href="/policy/privacy">
            <Typography variant="bXs" color="grey" text="Policy" />
          </a>
          <Typography variant="bXs" color="purple-header" text=" and " />
          <a href="/terms">
            <Typography variant="bXs" color="grey" text="Terms of Service" />
          </a>
        </span>
      </div>

      <div className="sign-up">
        <Typography
          variant="bSm"
          color="purple-header"
          text={isLogin ? "Don't have an account?" : "Already have an account?"}
        />
        <Button
          label={`Sign ${isLogin ? "Up" : "in"}`}
          onClick={() => {
            if (!isLogin) {
              setModalMode(LOGIN_SIGN_UP_MODAL_MODES.login);
            } else if (window.location.href.includes("for-companies")) {
              setModalMode(LOGIN_SIGN_UP_MODAL_MODES.companySignUp);
            } else {
              setModalMode(LOGIN_SIGN_UP_MODAL_MODES.volunteerSignUp);
            }
          }}
          variant="text"
        />
      </div>
    </div>
  );
};
