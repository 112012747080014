/** OpportunityCardLargeHeader documentation
 */
import { useState } from "react";
import { GlobeIcon } from "../../../icons/GlobeIcon";
import { LinkedInIcon } from "../../../icons/LinkedInIcon";
import { Typography } from "../../sharedComponents/Typography";
import { LikeRibbon } from "../../sharedComponents/LikeRibbon/LikeRibbon";
import { CardTextLink } from "../../sharedComponents/CardTextLink/CardTextLink";
import { CardDetailTags } from "../../sharedComponents/CardDetailTags/CardDetailTags";
import "./styles.css";


export const OpportunityCardLargeHeader = ({
  liked,
  jobTitle,
  companyName,
  companyId,
  linkedInUrl,
  websiteUrl,
  opportunityType,
  location,
  duration,
  cause,
  opportunityId,
}) => {
  // * internal state to display LikeRibbon filled/unfilled without
  // * having to make an API call to get most recent data
  const [isLiked, setIsLiked] = useState(liked ?? false);
  // TODO: isLoading state


  return (
    <section className="root OpportunityCardLargeHeader">
      <div className="title">
        <Typography variant="hMd" color="black" text={jobTitle} />
        <LikeRibbon
          isLiked={isLiked}
          setIsLiked={setIsLiked}
          opportunityId={opportunityId}
        />
      </div>

      <div className="company">
        <div className="links">
          <CardTextLink label={companyName} href={`/company/${companyId}`} />
          <CardTextLink
            label="View all opportunities"
            href={`/company/${companyId}/opportunities`}
          />
        </div>

        <div className="icons">
          {linkedInUrl && <a href={linkedInUrl} target="_blank" rel="noreferrer">
            <LinkedInIcon color="#FFA400" />
          </a>}
          {websiteUrl && <a href={websiteUrl} target="_blank" rel="noreferrer">
            <GlobeIcon />
          </a>}
        </div>
      </div>

      <div className="detail-tags">
        <CardDetailTags
          opportunityType={opportunityType}
          location={location}
          duration={duration}
          cause={cause}
        />
      </div>
    </section>
  );
};
