import "./styles.css";
import { useState } from "react";

export default function CausesModal({ isOpen, causes, checkedCauses, setCheckedCauses }) {
  const [selectAllChecked, setSelectAllChecked] = useState(false);

  // Function to handle "Select All" action
  const handleSelectAll = () => {
    setSelectAllChecked(!selectAllChecked);
    if (!selectAllChecked) {
      setCheckedCauses(causes.map((cause) => cause.id));
    } else {
      setCheckedCauses([-1]);
    }
  };

  // Function to handle individual checkbox changes
  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setCheckedCauses((prevCheckedCauses) => [...prevCheckedCauses, parseInt(value)]);
    } else {
      setCheckedCauses((prevCheckedCauses) =>
        prevCheckedCauses.filter((cause) => cause !== parseInt(value))
    );
    }
  };

  return (
    <div
      className="causes-popup"
      style={{ display: isOpen ? "block" : "none" }}
    >
      <div style={{ padding: "1em 2em" }}>
        <div style={{ cursor: "pointer" }} onClick={handleSelectAll}>
          {selectAllChecked ? "Reset" : "Select All"}
        </div>
        <form className="causes">
          {causes?.length &&
            causes.map((cause) => (
              <label key={cause.id ?? cause.cause}>
                <input
                  type="checkbox"
                  // TODO: enact as cause.id after BE sends ids
                  // value={cause.id}
                  value={cause.id}
                  checked={checkedCauses.includes(cause.id)}
                  onChange={handleCheckboxChange}
                />
                {cause.cause}
              </label>
            ))}
        </form>
      </div>
    </div>
  );
}
