export const FloppyDiskIcon = ({ color = "#312C47", className }) => {
  return (
    <svg
      className={`root FloppyDiskIcon ${className ?? ''}`}
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.3335 0.333008C1.80306 0.333008 1.29436 0.543721 0.919282 0.918794C0.54421 1.29387 0.333496 1.80257 0.333496 2.33301V11.6663C0.333496 12.1968 0.54421 12.7055 0.919282 13.0806C1.29436 13.4556 1.80306 13.6663 2.3335 13.6663H11.6668C12.1973 13.6663 12.706 13.4556 13.081 13.0806C13.4561 12.7055 13.6668 12.1968 13.6668 11.6663V4.33301C13.6668 4.1562 13.5966 3.98663 13.4716 3.8616L10.1382 0.52827C10.0132 0.403246 9.84364 0.333008 9.66683 0.333008H2.3335ZM2.3335 1.66634C2.15668 1.66634 1.98712 1.73658 1.86209 1.8616C1.73707 1.98663 1.66683 2.1562 1.66683 2.33301V11.6663C1.66683 11.8432 1.73707 12.0127 1.86209 12.1377C1.98712 12.2628 2.15669 12.333 2.3335 12.333H3.00016V7.66634C3.00016 7.29815 3.29864 6.99967 3.66683 6.99967H10.3335C10.7017 6.99967 11.0002 7.29815 11.0002 7.66634V12.333H11.6668C11.8436 12.333 12.0132 12.2628 12.1382 12.1377C12.2633 12.0127 12.3335 11.8432 12.3335 11.6663V4.60915L9.39069 1.66634H4.3335V3.66634H9.00016C9.36835 3.66634 9.66683 3.96482 9.66683 4.33301C9.66683 4.7012 9.36835 4.99967 9.00016 4.99967H3.66683C3.29864 4.99967 3.00016 4.7012 3.00016 4.33301V1.66634H2.3335ZM4.3335 8.33301V12.333H9.66683V8.33301H4.3335Z"
        fill={color}
      />
    </svg>
  );
};
