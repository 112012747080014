/** OpportunityCardLargePostingBody documentation
 */
import { Typography } from "../../sharedComponents/Typography"
import "./styles.css";
import { Button } from "../../sharedComponents/Button/Button";

export const OpportunityCardLargePostingBody = ({
  description,
  opportunityId,
  responsibilities,
  requirements,
  posted,
  postedBy,
  interviewProcess,
  funFacts,
  postedById,
  duration,
  hours
}) => {

  return (
    <section className="root OpportunityCardLargePostingBody">

        {/* left column */}
        <div className="content">

          <div className="details info">
            <Typography
              variant="hSm"
              color="black"
              text="Details" />
            <Typography
              variant="bMd"
              color="black"
              text={description} />
          </div>

          {requirements?.length &&<div className="requirements info">
            <Typography
              variant="hSm"
              color="black"
              text="Requirements" />
            <ul>
              {requirements?.length && requirements.map((r) => {
                return (
                  <Typography
                    variant="bMd"
                    color="black"
                    text={r}
                    htmlTag="li"
                    key={`${opportunityId}-${r.substring(0,4).trim()}`} />)
              })}
              </ul>
          </div>}

          <div className="responsibilities info">
              <Typography
                variant="hSm"
                color="black"
                text="Responsibilities" />
              <Typography
                variant="bMd"
                color="black"
                text={responsibilities}/>
          </div>

        <div className="button-container">
          <Button
            label="Apply"
            onClick={() => {
              // TODO: insert function to open ApplicationPopUp
            }}
          />
        </div>
        </div>



      {/* right column */}
      <div className="additional-information ">
        <div className="commitment">
          <Typography
            variant="hXs"
            color="white"
            text="Commitment" />
            <Typography
              variant="bXs"
              color="white"
              text={`Hours: ${ hours} hours per week`}/>
            <Typography
              variant="bXs"
              color="white"
              text={`Minimum period: ${duration } months`}/>

        </div>

        {interviewProcess?.length && <div className="interview-process">
            <Typography
            variant="hXs"
            color="white"
            text="Interview process" />
            <ol>
              {interviewProcess?.length && interviewProcess.map((step) => {
                return (
                  <Typography
                    variant="bXs"
                    color="white"
                    text={step}
                    htmlTag="li"
                    key={step.substring(0, 8)} />)
              })}
            </ol>
        </div>}

        {funFacts && <div className="fun-facts">
            <Typography
            variant="hXs"
            color="white"
            text="Fun facts about us" />
            <Typography
              variant="bXs"
              color="white"
              text={funFacts}/>
        </div>}

        <div className="posted">
            <Typography
            variant="hXs"
            color="white"
            text="Posted by" />
          {/* assuming date will be an ISOString. Turn into a Date and format as US date */}
          <span className="one-line">
            <Typography
              variant="bXs"
              color="white"
              text={`${new Intl.DateTimeFormat('en-US').format(new Date(posted))} by `}/>
            <a className="posted-by" href={`companies/${postedById}`}>
            <Typography
              variant="bXs"
              color="white"
              text={postedBy}
              htmlTag="span"/>
            </a>
              </span>
        </div>

      </div>
    </section>
  );
};
