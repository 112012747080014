import React from "react";
import { Typography } from "../../sharedComponents/Typography/Typography";
import { Button } from "../../sharedComponents/Button/Button";
import {
  BasicTextInput,
  EmailInput,
  PasswordInput,
  AutoCompleteTextInput,
  TextArea,
  FileInput
} from "../../sharedComponents/TextInput";
import { SignUpLogInFooter } from "../SignUpLogInFooter/SignUpLogInFooter";
import { CAUSES } from "./constants";
import { validEIN, validEmail, validPassword, validUrl } from "../../../constants/utils";

const ERROR_STATES = {
  email: "invalid_email",
  password: "invalid_password",
  passwordConfirm: "password_not_confirmed",
  companyName: "no_company_name",
  location: "no_location",
  portfolioUrl: "invalid_url",
  cause: "no_cause",
  ein: "invalid_ein",
  socialMediaUrl: "invalid_url",
}
const ERROR_MESSAGES = {
  email: "Please enter a valid email address at most 100 characters",
  password: "Password must be 8 to 100 characters long with number, symbol, lowercase, and uppercase letters.",
  passwordConfirm: "Passwords do not match. Try again.",
  companyName: "Please enter company name",
  location: "Please enter location",
  portfolioUrl: "Please enter a valid URL. Must include http(s)://www.",
  cause: "Please enter causes",
  ein: "Please enter valid EIN Number. Format: XX-XXXXXXX",
  socialMediaUrl: "Please enter a valid URL. Must include http(s)://www.",
}

export const Page1 = ({
  form,
  handleChange,
  handleValidation,
  setFormError,
  setStep,
  formError,
  setModalMode,
}) => {

  return (
    <>
      <form>
        <EmailInput
          value={form.email}
          placeholder="Email Address"
          name={"email"}
          onChange={(e)=>{handleValidation(e, formError.email, validEmail)}}
        />
        {formError.email === ERROR_STATES.email && (
          <Typography
            variant="bMd"
            color="red"
            text={ERROR_MESSAGES.email}
          />
        )}
        <PasswordInput
          value={form.password}
          placeholder="Password"
          name={"password"}
          onChange={(e)=>{handleValidation(e, formError.password, validPassword)}}
          showToggle={true}
        />
        {formError.password === ERROR_STATES.password && (
          <Typography
            variant="bMd"
            color="red"
            text={ERROR_MESSAGES.password}
          />
        )}
        <PasswordInput
          value={form.passwordConfirm}
          placeholder="Confirm Password"
          name={"passwordConfirm"}
          onChange={(e)=>{handleValidation(e, formError.password, (val)=>{return val===form.password})}}
          showToggle={false}
        />
        {formError.password === ERROR_STATES.passwordConfirm && (
          <Typography
            variant="bMd"
            color="red"
            text={ERROR_MESSAGES.passwordConfirm}
          />
        )}
        <Button
          className={"create-account"}
          label="Create Company Account"
          onClick={(event) => {
            // validate email
            if (!validEmail(form.email)) {
              event.preventDefault();
              setFormError({ email: ERROR_STATES.email });
              console.error(ERROR_MESSAGES.email);
              return;
            }

            // validate password
            if (!validPassword(form.password)) {
              event.preventDefault();
              setFormError({ password: ERROR_STATES.password });
              console.error(ERROR_MESSAGES.password);
              return;
            } else if (form.password !== form.passwordConfirm) {
              event.preventDefault();
              setFormError({ password: ERROR_STATES.passwordConfirm });
              console.error(ERROR_MESSAGES.passwordConfirm);
              return;
            }

            setStep((prev) => prev + 1);
          }}
        />
      </form>
      <SignUpLogInFooter isLogin={false} setModalMode={setModalMode} />
    </>
  );
};

export const Page2 = ({
  form,
  setForm,
  handleChange,
  handleValidation,
  setStep,
  setFormError,
  formError,
}) => {

  return (
    <form>
      <BasicTextInput
        onChange={(e)=>{handleValidation(e, formError.companyName, (val)=>{return val.trim() !== ""})}}
        label="Company's Name"
        name="companyName"
        value={form.companyName}
        placeholder="ex. Career Pathway Institute"
        required={true}
      />
      {formError.companyName === ERROR_STATES.companyName && (
        <Typography
          variant="bMd"
          color="red"
          text={ERROR_MESSAGES.companyName}
        />
      )}
      <BasicTextInput
        onChange={(e)=>{handleValidation(e, formError.location, (val)=>{return val.trim() !== ""})}}
        label="Location"
        name="location"
        value={form.location}
        placeholder="ex. San Francisco, CA"
        required={true}
      />
      {formError.location === ERROR_STATES.location && (
        <Typography
          variant="bMd"
          color="red"
          text={ERROR_MESSAGES.location}
        />
      )}
      <BasicTextInput
        onChange={(e)=>{handleValidation(e, formError.portfolioUrl, validUrl)}}
        label="Portfolio/ website"
        name="portfolioUrl"
        value={form.portfolioUrl}
        placeholder="ex. https://www.github.com/..."
      />
      {formError.portfolioUrl === ERROR_STATES.portfolioUrl && (
        <Typography
          variant="bMd"
          color="red"
          text={ERROR_MESSAGES.portfolioUrl}
        />
      )}
      <TextArea
        placeholder={"ex. https://www.linkedin.com/..."}
        label={"Short bio"}
        required={true}
        rows={3}
        value={form.bio}
        name={"bio"}
        resize={false}
        onChange={handleChange}
      />
      <FileInput
        label="Logo"
        name="fileInput"
        placeholder={"Attach logo"}
        value={form.logo}
        handleChange={(e) => {
          if (e.target.files) {
            setForm({ ...form, logo: e.target.files[0] });
          }
        }}
      />
      <Button
        className={"next-step"}
        label="Next Step"
        onClick={(event) => {
          event.preventDefault();
          // company name required
          if (!form.companyName || form.companyName.trim() === "") {
            setFormError({ companyName: ERROR_STATES.companyName });
            console.error(ERROR_MESSAGES.companyName);
            return;
          } else {
            setFormError({ companyName: "" });
          }

          // location required
          if (!form.location || form.location.trim() === "") {
            setFormError({ location: ERROR_STATES.location });
            console.error(ERROR_MESSAGES.location);
            return;
          } else {
            setFormError({ location: "" });
          }

          // validate portfolio url
          if (form.portfolioUrl && !validUrl(form.portfolioUrl)) {
            setFormError({ portfolioUrl: ERROR_STATES.portfolioUrl });
            console.error(ERROR_MESSAGES.portfolioUrl);
            return;
          } else {
            setFormError({ portfolioUrl: "" });
          }
          setStep((prev) => prev + 1);
        }}
      />
      <Button
        className={"back-button"}
        label="Back to previous step"
        onClick={() => {
          setStep((prev) => prev - 1);
        }}
        variant="text"
      />
    </form>
  );
};

export const Page3 = ({
  form,
  setForm,
  handleChange,
  handleValidation,
  setFormError,
  setStep,
  formError,
}) => {

  // TODO: add useEffect to fetchCauses() instead of using CAUSES constants
  // !! BLOCKED: /causes endpoint does not return data on Stage server */

  return (
    <form>
      <AutoCompleteTextInput
        placeholder={"Type or select"}
        label={"Causes"}
        value={form.cause}
        name={"cause"}
        options={CAUSES}
        onChange={(e)=>{handleValidation(e, formError.cause, (val)=>{return val.trim() !== ""})}}
        required={true}
      />
      {formError.cause === ERROR_STATES.cause && (
        <Typography
          variant="bMd"
          color="red"
          text={ERROR_MESSAGES.cause}
        />
      )}
      <BasicTextInput
        onChange={(e)=>{handleValidation(e, formError.ein, validEIN)}}
        label="EIN Number"
        name="ein"
        value={form.ein}
        placeholder="00-0000000"
        required={true}
      />
      {formError.ein === ERROR_STATES.ein && (<>
        <Typography variant="bMd" color="red" text={ERROR_MESSAGES.ein} />
        {/* <Typography variant="bMd" color="red" text="Format: XX-XXXXXXX" /> */}
        </>)}

      <BasicTextInput
        onChange={(e)=>{handleValidation(e, formError.socialMediaUrl, validUrl)}}
        label="Link to your main social media profile"
        name="socialMediaUrl"
        value={form.socialMediaUrl}
        placeholder="ex. https://www.instagram.com/..."
      />
      {formError.socialMediaUrl === ERROR_STATES.socialMediaUrl && (
        <Typography
          variant="bMd"
          color="red"
          text={ERROR_MESSAGES.socialMediaUrl}
        />
      )}

      <Button
        className={"sign-up"}
        label="Sign up"
        onClick={(event) => {
          event.preventDefault();

          // required causes
          // FIX: validation not working
          console.log("cause", form.cause)
          if (!form.cause || form.cause.trim() === "") {
            setFormError({ cause: ERROR_STATES.cause });
            console.error(ERROR_MESSAGES.cause);
            return;
          } else {
            setFormError({ cause: "" });
          }
          
          // validate EIN
          if (!validEIN(form.ein)) {
            setFormError({ ein: ERROR_STATES.ein });
            console.error(ERROR_MESSAGES.ein);
            return;
          } else {
            setFormError({ ein: "" });
          }
          
          // validate URL
          if (form.socialMediaUrl && !validUrl(form.socialMediaUrl)) {
            setFormError({ socialMediaUrl: ERROR_STATES.socialMediaUrl });
            console.error(ERROR_MESSAGES.socialMediaUrl);
            return;
          } 

          // TODO: API call to backend to create User

        }}
      />

      <Button
        label="Back to previous step"
        onClick={() => {
          setStep((prev) => prev - 1);
        }}
        variant="text"
      />
    </form>
  );
};
