import React from "react";
import { Typography } from "../../sharedComponents/Typography/Typography";
import { Button } from "../../sharedComponents/Button/Button";
import {
  BasicTextInput,
  EmailInput,
  PasswordInput,
  AutoCompleteTextInput,
  TextArea,
  InputTextList,
  FileInput
} from "../../sharedComponents/TextInput";
import { SignUpLogInFooter } from "../SignUpLogInFooter/SignUpLogInFooter";

import { JOB_TITLES } from "./constants";
import { validUrl, validEmail, validPassword } from "../../../constants/utils";

const ERROR_STATES = {
  email: "invalid_email",
  password: "invalid_password",
  passwordConfirm: "password_not_confirmed",
  firstName: "no_first_name",
  lastName: "no_last_name",
  location: "no_location",
  linkedInUrl: "invalid_url",
  portfolioUrl: "invalid_url",
  jobTitle: "no_job_title",
  skills: "no_skills",
}

const ERROR_MESSAGES = {
  email: "Please enter a valid email address at most 100 characters",
  password: "Password must be 8 to 100 characters long with number, symbol, lowercase, and uppercase letters.",
  passwordConfirm: "Passwords do not match. Try again.",
  firstName: "Please enter first name",
  lastName: "Please enter last name",
  location: "Please enter location",
  linkedInUrl: "Please enter a valid URL. Must include http(s)://www.",
  portfolioUrl: "Please enter a valid URL. Must include http(s)://www.",
  jobTitle: "Please enter job title",
  skills: "Please enter skills",
}

export const Page1 = ({
  form,
  handleChange,
  handleValidation,
  setFormError,
  setStep,
  formError,
  setModalMode,
}) => {

  return (
    <>
      <form>
        <EmailInput
          value={form.email}
          placeholder="Email Address"
          name={"email"}
          onChange={(e)=>{handleValidation(e, formError.email, validEmail)}}
        />
        {formError.email === ERROR_STATES.email && (
          <Typography
            variant="bMd"
            color="red"
            text={ERROR_MESSAGES.email}
          />
        )}
        <PasswordInput
          value={form.password}
          placeholder="Password"
          name={"password"}
          onChange={(e)=>{handleValidation(e, formError.password, validPassword)}}
          showToggle={true}
        />
        {formError.password === ERROR_STATES.password && (
          <Typography
            variant="bMd"
            color="red"
            text={ERROR_MESSAGES.password}
          />
        )}
        <PasswordInput
          value={form.passwordConfirm}
          placeholder="Confirm Password"
          name={"passwordConfirm"}
          onChange={(e)=>{handleValidation(e, formError.password, (val)=>{return val===form.password})}}
          showToggle={false}
        />
        {formError.password === ERROR_STATES.passwordConfirm && (
          <Typography
            variant="bMd"
            color="red"
            text={ERROR_MESSAGES.passwordConfirm}
          />
        )}
        <Button
          className={"create-account"}
          label="Create Account"
          onClick={(event) => {
            // validate email
            if (!validEmail(form.email)) {
              event.preventDefault();
              setFormError({ email: ERROR_STATES.email });
              console.error(ERROR_MESSAGES.email);
              return;
            }

            // validate password
            if (!validPassword(form.password)) {
              event.preventDefault();
              setFormError({ password: ERROR_STATES.password });
              console.error(ERROR_MESSAGES.password);
              return;
            } else if (form.password !== form.passwordConfirm) {
              event.preventDefault();
              setFormError({ password: ERROR_STATES.passwordConfirm });
              console.error(ERROR_MESSAGES.passwordConfirm);
              return;
            }

            setStep((prev) => prev + 1);
          }}
        />
      </form>
      <SignUpLogInFooter isLogin={false} setModalMode={setModalMode} />
    </>
  );
};

export const Page2 = ({
  form,
  setForm,
  handleChange,
  handleValidation,
  setStep,
  setFormError,
  formError,
}) => {

  return (
    <form>
      <div className="name-container">
        <BasicTextInput
          onChange={(e)=>{handleValidation(e, formError.firstName, (val)=>{return val.trim() !== ""})}}
          label="First Name"
          name="firstName"
          value={form.firstName}
          placeholder="ex. John"
          required={true}
        />
        <BasicTextInput
          onChange={(e)=>{handleValidation(e, formError.lastName, (val)=>{return val.trim() !== ""})}}
          label="Last Name"
          name="lastName"
          value={form.lastName}
          required={true}
          placeholder="ex. McJohn"
        />
      </div>
        {formError.firstName === ERROR_STATES.firstName && (
          <Typography
            className='left'
            variant="bMd"
            color="red"
            text={ERROR_MESSAGES.firstName}
            />
        )}
      {formError.lastName === ERROR_STATES.lastName && (
        <Typography
          className='right'
          variant="bMd"
          color="red"
          text={ERROR_MESSAGES.lastName}
        />
      )}
      <BasicTextInput
        onChange={(e)=>{handleValidation(e, formError.location, (val)=>{return val.trim() !== ""})}}
        label="Location"
        name="location"
        value={form.location}
        placeholder="ex. San Francisco, CA"
        required={true}
      />
      {formError.location === ERROR_STATES.location && (
        <Typography
          variant="bMd"
          color="red"
          text={ERROR_MESSAGES.location}
        />
      )}
      <BasicTextInput
        onChange={(e)=>{handleValidation(e, formError.linkedInUrl, validUrl)}}
        label="LinkedIn Profile"
        name="linkedInUrl"
        value={form.linkedInUrl}
        placeholder="ex. https://www.linkedin.com/..."
      />
      {formError.linkedInUrl === ERROR_STATES.linkedInUrl && (
        <Typography
          variant="bMd"
          color="red"
          text={ERROR_MESSAGES.linkedInUrl}
        />
      )}
      <BasicTextInput
        onChange={(e)=>{handleValidation(e, formError.portfolioUrl, validUrl)}}
        label="Portfolio/ website"
        name="portfolioUrl"
        value={form.portfolioUrl}
        placeholder="ex. https://www.github.com/..."
      />
      {formError.portfolioUrl === ERROR_STATES.portfolioUrl && (
        <Typography
          variant="bMd"
          color="red"
          text={ERROR_MESSAGES.portfolioUrl}
        />
      )}
      <FileInput
        label="Resume"
        name="fileInput"
        placeholder={"Attach resume"}
        value={form.resume}
        handleChange={(e) => {
          if (e.target.files) {
            setForm({ ...form, resume: e.target.files[0] });
          }
        }}
      />
      <Button
        className={"next-step"}
        label="Next Step"
        onClick={(event) => {
          event.preventDefault();

          // name and location required
          if (!form.firstName || form.firstName.trim() === "") {
            setFormError({ firstName: ERROR_STATES.firstName });
            console.error(ERROR_MESSAGES.firstName);
            return;
          } else {
            setFormError({ firstName: "" });
          }

          if (!form.lastName || form.lastName.trim() === "") {
            setFormError({ lastName: ERROR_STATES.lastName });
            console.error(ERROR_MESSAGES.lastName);
            return;
          } else {
            setFormError({ lastName: "" });
          }

          if (!form.location || form.location.trim() === "") {
            setFormError({ location: ERROR_STATES.location });
            console.error(ERROR_MESSAGES.location);
            return;
          } else {
            setFormError({ location: "" });
          }

          // validate urls
          if (form.linkedInUrl && !validUrl(form.linkedInUrl)) {
            setFormError({ linkedInUrl: ERROR_STATES.linkedInUrl });
            console.error(ERROR_MESSAGES.linkedInUrl);
            return;
          } else if (form.portfolioUrl && !validUrl(form.portfolioUrl)) {
            setFormError({ portfolioUrl: ERROR_STATES.portfolioUrl });
            console.error(ERROR_MESSAGES.portfolioUrl);
            return;
          }
          setStep((prev) => prev + 1);
        }}
      />
      <Button
        className={"back-button"}
        label="Back to previous step"
        onClick={() => {
          setStep((prev) => prev - 1);
        }}
        variant="text"
      />
    </form>
  );
};

export const Page3 = ({
  form,
  setForm,
  handleChange,
  handleValidation,
  setStep,
  setFormError,
  formError
}) => {
  // TODO: if an endpoint is created for JOB_TITLES, add useEffect to pull updated list
  return (
    <form>
      <AutoCompleteTextInput
        placeholder={"Type or select"}
        label={"Title/profession"}
        value={form.title}
        name={"title"}
        options={JOB_TITLES}
        onChange={(e)=>{handleValidation(e, formError.title, (val)=>{return val.trim() !== ""})}}
        required={true}
      />
      {formError.title === ERROR_STATES.jobTitle && (
        <Typography
          variant="bMd"
          color="red"
          text={ERROR_MESSAGES.jobTitle}
        />
      )}

      <InputTextList
        placeholder={"ex. Project management, Java, etc."}
        label={"Add your 5 main skills"}
        value={form.skills}
        handleValidation={handleValidation}
        setValue={(val) => setForm({ ...form, skills: val })}
        required={true}
      />
      {formError.skills === ERROR_STATES.skills && (
        <Typography
          variant="bMd"
          color="red"
          text={ERROR_MESSAGES.skills}
        />
      )}

      <TextArea
        placeholder={
          "Brief, compelling introduction to oneself or idea, usually within 30 seconds."
        }
        label={"Elevator speech"}
        required={true}
        rows={4}
        value={form.elevatorPitch}
        name={"elevatorPitch"}
        resize={false}
        onChange={handleChange}
      />
      <Button
        className={"sign-up"}
        label="Sign up"
        onClick={(event) => {
          event.preventDefault();

          // required title
          if (!form.title || form.title.trim() === "") {
            event.preventDefault();
            setFormError({ title: ERROR_STATES.jobTitle });
            console.error(ERROR_MESSAGES.jobTitle);
            return;
          } else {
            setFormError({ title: "" });
          }

          // validate skills
          if (!form.skills || form.skills.length === 0) {
            event.preventDefault();
            setFormError({ skills: ERROR_STATES.skills });
            console.error(ERROR_MESSAGES.skills);
            return;
          } else {
            setFormError({ skills: "" });
          }
          /**
           * TODO:
           * API call to backend to create User
           * if success
           *    store token in localStorage using LocalStorageService
           * else
           *    show error response
          */

          // ? or will we create user when form is complete?
        }}
      />

      <Button
        className={"back-button"}
        label="Back to previous step"
        onClick={() => {
          setStep((prev) => prev - 1);
        }}
        variant="text"
      />
    </form>
  );
};
