/** LoginSignUpModal documentation
 */
import { Modal } from "../sharedComponents/Modal";
import { LoginForm } from "../forms/LoginForm";
import "./styles.css";
import { SignUpCompanyForm } from "../forms/SignUpCompanyForm";
import { SignUpVolunteerForm } from "../forms/SignUpVolunteerForm";

export const LOGIN_SIGN_UP_MODAL_MODES = {
  login: "login",
  companySignUp: "companySignUp",
  volunteerSignUp: "volunteerSignUp",
};

export const LoginSignUpModal = ({
  isLoginSignUpOpen,
  setIsLoginSignUpOpen,
  modalMode,
  setModalMode,
}) => {

  return (
    <Modal
      className="root LoginSignUpModal"
      isOpen={isLoginSignUpOpen}
      closeModal={() => {
        setIsLoginSignUpOpen(false);
      }}
    >
      {modalMode === LOGIN_SIGN_UP_MODAL_MODES.login ? (
        <LoginForm setModalMode={setModalMode}/>
      ) : modalMode === LOGIN_SIGN_UP_MODAL_MODES.companySignUp ? (
        <SignUpCompanyForm setModalMode={setModalMode}/>
      ) : (
        <SignUpVolunteerForm setModalMode={setModalMode}/>
      )}
    </Modal>
  );
};
