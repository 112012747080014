/** LoginForm documentation
 */
import { useState } from "react";
import { Typography } from "./../../sharedComponents/Typography/Typography";
import { Button } from "./../../sharedComponents/Button/Button";
import { EmailInput, PasswordInput } from "./../../sharedComponents/TextInput";

import "./styles.css";
import { SignUpLogInFooter } from "../SignUpLogInFooter/SignUpLogInFooter";

const INITIAL_FORM_STATE = { email: "", password: "" };
const INITIAL_ERROR_STATE = { password: "" };

const ERROR_STATES = {
  credentials: "incorrect_credentials"
}
const ERROR_MESSAGES= {
  credentials: "Incorrect email or password"
}

export const LoginForm = ({ setModalMode }) => {
  const [form, setForm] = useState(INITIAL_FORM_STATE);
  const [formError, setFormError] = useState(INITIAL_ERROR_STATE);

  const handleChange = (event) => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };


    const login = (email, password) => {
    /**
     * TODO:
     * make API call to sign in
     * if success
     *    store token in localStorage using LocalStorageService
     * else
     *    show error response
     */

    // return user else null ?
    return null;
  }

  return (
    <div className="root LoginForm">
      <form>
        <Typography
          variant="hSm"
          color="black"
          text="Welcome Back to CPI board"
        />
        <EmailInput
          value={form.email}
          placeholder="Email Address"
          name={"email"}
          onChange={handleChange}
        />
        <PasswordInput
          value={form.password}
          placeholder="Password"
          name={"password"}
          onChange={handleChange}
          showToggle={true}
        />
        {formError.login === ERROR_STATES.credentials && (
          <Typography
            variant="bMd"
            color="red"
            text={ERROR_MESSAGES.credentials}
          />
        )}
        {/* //!! the UI for a forgotten password has not been defined.
                Given the convention of having SignUp and LogIn in the
                same Modal, this may also become a screen. If that becomes
                the case, then a.forgot-password-link will become a text
                Button whose onClick will change modalMode (see
                SignUpLogInFooter below)
        */}
        <a href="/forgot-password" className="forgot-password-link">
          <Typography variant="bMd" color="orange" text="Forgot password?" />
        </a>
        <Button label="Sign In" onClick={(event) => {
          if (!login(form.email, form.password)) {
            event.preventDefault();
            setFormError({ login: ERROR_STATES.credentials });
            console.error(ERROR_MESSAGES.credentials);
            return;
          }
        }} />
      </form>

      <SignUpLogInFooter isLogin={true} setModalMode={setModalMode} />
    </div>
  );
};
