import { Typography } from "../../sharedComponents/Typography";
import { content } from "./assets/content";
import { API } from "../../../api";
import { useState, useEffect } from "react";

import { Infographic } from "./Infographic/Infographic";
import "./WhyVolunteer.css";

export function WhyVolunteer() {

  const [stats, setStats] = useState({});
  
  useEffect(() => {
      const fetchStats = async () => {
        const data = await API.stats.getStats();
        setStats(data);
      };
    
      fetchStats();
  }, []);

  return (
    <section className="why-volunteer-wrapper">

      <Typography
        variant="hMd"
        color="purple-header"
        text="Why volunteer with us?"
      />
      <div className="infographics-wrapper">
        <Infographic bigText={`${stats.numberOfVolunteers || content[0].bigText}+`} smallText={content[0].smallText} img={content[0].img} key={`${stats.numberOfVolunteers} ${content[0].smallText}`} />
        <Infographic bigText={`${stats.numberOfLanguages || content[1].bigText}`}  smallText={content[1].smallText} img={content[1].img} key={`${stats.numberOfLanguages} ${content[1].smallText}`} />
        <Infographic bigText={`${stats.numberOfProjects || content[2].bigText}+`}  smallText={content[2].smallText} img={content[2].img} key={`${stats.numberOfProjects} ${content[2].smallText}`} />
      </div>
    </section>
  );
}
