export const testimonials = [
  {
    id: 1,
    author: "Alina Arsaeva",
    testimonial:
      "This was my first volunteer experience, and I can say that it was a great opportunity to adapt to a new country and start leading regular meetings in English. The most important thing is that the team values you so much that they constantly remind you and ask you not to forget about your own interests within the project.",
  },
  {
    id: 2,
    author: "Eugene Vorontsov",
    testimonial:
    `The opportunity to contribute my time and skills to a worthy cause such as CPI has left a lasting impression on my heart. The dedication and enthusiasm of the staff and fellow volunteers have inspired me to become a better version of myself. Through my involvement, I've gained invaluable experience, forged meaningful connections, and discovered a profound sense of purpose. I'm grateful for the chance to make a difference and proud to be part of such an amazing community.`
  },
  {
    id: 3,
    author: "Marina Komarchuk",
    testimonial:
    `Volunteering with CPI has been an incredibly rewarding experience for me. The sense of fulfillment I get from giving back to my community is unmatched. The team at CPI is incredibly supportive and passionate about their cause, which makes every project feel like a meaningful contribution to something greater than myself. I've learned so much and grown both personally and professionally through my volunteer work here. I highly recommend anyone looking to make a positive impact to get involved with CPI – you won't regret it!`
  }
];

export default testimonials;
