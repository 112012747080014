import "./styles.css";
import { LikeRibbonOutlineIcon } from "../../../icons/LikeRibbonOutlineIcon";
import { LocalStorageService } from "../../../utils/LocalStorageService";
import { LOCAL_STORAGE_KEYS } from "../../../constants/localStorage";
import { API } from "../../../api";
import { BlockedIcon } from "../../../icons/BlockedIcon";
import { useState } from "react";
import { Typography } from "../Typography";
import { Loader } from "../Loader";

const INITIAL_ERROR_MSG = "Network error, try again later";

export const LikeRibbon = ({ isLiked = false, setIsLiked, opportunityId }) => {
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(INITIAL_ERROR_MSG);
  const [isLoading, setIsLoading] = useState(false);

  // getting auth info from local localStorage
  const locals = LocalStorageService();
  const userId = locals.get(LOCAL_STORAGE_KEYS.USER_ID);
  const authToken = locals.get(LOCAL_STORAGE_KEYS.TOKEN);

  const clickRibbon = async () => {
    setIsLoading(true);

    const isLoggedIn = userId && authToken;
    // TODO: add checking if token expiration is passed, currently not expiry date from BackEnd

    setErrorMessage(isLoggedIn ? INITIAL_ERROR_MSG : "Please Log In");

    const response = await API.favorites.favoriteOpp(userId, opportunityId);
    if (response?.create_date) {
      setIsLiked(true);
      setIsError(false);
    } else if (response?.message === "Favorite successfully removed") {
      setIsLiked(false);
      setIsError(false);
    } else {
      setIsError(true);
    }

    setIsLoading(false);
  };

  const ErrorMessage = ({ errorMessage }) => {
    return (
      <div className="root ErrorMessage">
        <BlockedIcon />
        <Typography variant="bSm" color="red" text={errorMessage} />
      </div>
    );
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <button className="LikeRibbon root" onClick={clickRibbon}>
          {isError ? (
            <ErrorMessage errorMessage={errorMessage} />
          ) : (
            <LikeRibbonOutlineIcon isFilled={isLiked} />
          )}
        </button>
      )}
    </>
  );
};
