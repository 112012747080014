/** SignUpHeader documentation
 */
import { PageControlIcon } from "../../../icons/PageControl";
import { Typography } from "../../sharedComponents/Typography/Typography";
import "./styles.css";

export const SignUpHeader = ({ headline, step, instruction }) => {
  return (
    <div className="root SignUpHeader">
      <Typography variant="hSm" color="purple-header" text={headline} />
      <PageControlIcon step={step} />
      <Typography variant="bMd" color="purple-header" text={instruction} />
    </div>
  );
};
