export const validPassword = (password) => {
    const passPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,100}$/
    return password.match(passPattern)
}

export const validEmail = (email) => {
    // [a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-] ===> includes any of these characters
    // @                               ===> one @ symbol
    // [a-zA-Z0-9-.]                   ===> includes alphanumeric characters or - or .
    // (?:\.[a-zA-Z0-9-]{2,4})         ===> includes . and then alphanumerics or - between 2 to 4 characters long
    const emailPattern = /^(?=.{1,100}$)[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-.]+(?:\.[a-zA-Z0-9-]{2,4})*$/
    return email.match(emailPattern)
}

export const validUrl = (url) => {
    const linkPattern = /^(http|https):\/\/(www\.)([-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6})$/ 
    return url.match(linkPattern)
}

export const validEIN = (ein) => {
    const einPattern = /^[0-9]\d?-\d{7}$/
    return ein.match(einPattern)
}