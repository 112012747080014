export const LocationPinIcon = ({ color = "#514A68" }) => {
  return (
    <svg
      width="10"
      height="13"
      viewBox="0 0 10 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 3.5C3.89543 3.5 3 4.39543 3 5.5C3 6.60457 3.89543 7.5 5 7.5C6.10457 7.5 7 6.60457 7 5.5C7 4.39543 6.10457 3.5 5 3.5ZM4 5.5C4 4.94772 4.44772 4.5 5 4.5C5.55228 4.5 6 4.94772 6 5.5C6 6.05228 5.55228 6.5 5 6.5C4.44772 6.5 4 6.05228 4 5.5Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.72238 12.4158C4.72252 12.4159 4.72265 12.416 5 12L4.72238 12.4158C4.89033 12.5278 5.1094 12.528 5.27735 12.416L5 12C5.27735 12.416 5.27721 12.4161 5.27735 12.416L5.27905 12.4149L5.28238 12.4127L5.29384 12.4049C5.30358 12.3983 5.31746 12.3888 5.33522 12.3765C5.37074 12.3519 5.42177 12.3161 5.4861 12.2696C5.61473 12.1767 5.79682 12.0411 6.01459 11.8677C6.44933 11.5215 7.03031 11.0214 7.61298 10.4064C8.75787 9.19788 10 7.45097 10 5.5C10 4.17392 9.47322 2.90215 8.53553 1.96447C7.59785 1.02678 6.32608 0.5 5 0.5C3.67392 0.5 2.40215 1.02678 1.46447 1.96447C0.526784 2.90215 0 4.17392 0 5.5C0 7.45097 1.24213 9.19788 2.38702 10.4064C2.96969 11.0214 3.55067 11.5215 3.98541 11.8677C4.20318 12.0411 4.38527 12.1767 4.5139 12.2696C4.57823 12.3161 4.62926 12.3519 4.66478 12.3765C4.68254 12.3888 4.69642 12.3983 4.70616 12.4049L4.71762 12.4127L4.72095 12.4149L4.72238 12.4158ZM2.17157 2.67157C2.92172 1.92143 3.93913 1.5 5 1.5C6.06087 1.5 7.07828 1.92143 7.82843 2.67157C8.57857 3.42172 9 4.43913 9 5.5C9 7.04903 7.99213 8.55212 6.88702 9.71863C6.34469 10.2911 5.80067 10.7597 5.39166 11.0854C5.23944 11.2066 5.10649 11.3076 5 11.3863C4.89351 11.3076 4.76056 11.2066 4.60834 11.0854C4.19933 10.7597 3.65531 10.2911 3.11298 9.71863C2.00787 8.55212 1 7.04903 1 5.5C1 4.43913 1.42143 3.42172 2.17157 2.67157Z"
        fill={color}
      />
    </svg>
  );
};
