/** CardBadge documentation
 */
import { Typography } from "../Typography";
import "./CardBadge.css";

export const CardBadge = ({text}) => {

  return (
    <Typography
      className={"CardBadge root"}
      variant="bSm"
      text={text}
      htmlTag="span"
      color="purple-header"
    />
  )
};
