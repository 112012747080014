import { Testimonials } from "../Testimonials";
import { WhyVolunteer } from "../WhyVolunteer";
import Infographics from "../Infographics.jsx";
import { NewestOpenings } from "../NewestOpenings/NewestOpenings.jsx";
import { Search } from "../Search";
import { Typography } from "../../sharedComponents/Typography/Typography.jsx";
import "./VolunteerBoard.css";

export function VolunteerBoard({
  setIsLoginSignUpOpen,
  setLoginSignUpModalMode,
}) {
  return (
    <div className="main-container">
      {/* NavBar and Footer are in VolunteerBoard in App */}
      <section className="hero">
        <div className="title">
          <Typography
            text="Impact. Friends. Joy."
            variant="hLg"
            color="white"
          />
          <Typography
            text="Welcome to Volunteer Board by CPI . Get more satisfaction from life by giving back to the community."
            variant="hXs"
            color="white"
            htmlTag="p"
          />
        </div>
        <Search />
      </section>
      <WhyVolunteer />
      <NewestOpenings />
      <Infographics />
      <Testimonials
        setIsLoginSignUpOpen={setIsLoginSignUpOpen}
        setLoginSignUpModalMode={setLoginSignUpModalMode}
      />
    </div>
  );
}
