// ? Temporary?: will there be an endpoint for these titles? */

export const JOB_TITLES = [
"Mentor",
"Tutor",
"Event Coordinator",
"Fundraiser",
"Grant Writer",
"Board Member",
"Web Developer",
"Social Media Manager",
"Marketing Consultant",
"Graphic Designer",
"Accountant / Financial Advisor",
"Lawyer / Legal Advisor",
"HR Consultant",
"Project Manager",
"Volunteer Coordinator",
"Teacher / Educator",
"Health Educator",
"Nurse / Medical Volunteer",
"IT Support Specialist",
"Research Assistant",
"Community Outreach Coordinator",
"Program Manager",
"Public Relations Specialist",
"Content Writer / Copywriter",
"Translator / Interpreter",
"Data Analyst",
"Business Consultant",
"Environmental Specialist",
"Crisis Counselor",
"Photographer / Videographer",
"Event Planner",
"Social Worker",
"Financial Planner",
"Nutritionist / Dietician",
"Veterinarian / Vet Assistant",
"Wilderness Guide",
"Fitness Instructor",
"Career Coach",
"Library Assistant",
"Literacy Tutor",
"Youth Mentor",
"Sports Coach / Trainer",
"Art Therapist",
"Musician / Performer",
"Legal Aid Volunteer",
"Immigration Specialist",
"Cultural Ambassador",
"ESL Teacher",
"Social Justice Advocate",
"Voter Registration Coordinator",
"STEM Education Volunteer",
"Energy Efficiency Consultant",
"Domestic Violence Counselor",
"Climate Change Activist",
"Mental Health Advocate",
"Diversity and Inclusion Trainer",
"Entrepreneurship Mentor",
"Financial Literacy Educator",
"Legal Rights Educator",
"Cybersecurity Specialist",
"Urban Planner",
"Fair Trade Advocate",
"Fashion Designer / Stylist",
"Medical Research Assistant",
"Telemedicine Volunteer",
"Community Theater Director",
"Music Therapist",
"Recycling Coordinator",
"STEM Outreach Coordinator",
"Eco - Tourism Guide",
"Literacy Program Coordinator",
]