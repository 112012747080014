/** CardDetailTags documentation
 * @param variant {string}: "full" || undefined
 * @param showLines {boolean}: show "pipe" character styling on all DetailTags
 */
import React from "react";

import "./styles.css";
import { ClockIcon } from "../../../icons/ClockIcon";
import { ComputerMonitorIcon,  } from "../../../icons/ComputerMonitorIcon";
import { HeartIcon } from "../../../icons/HeartIcon";
import { LocationPinIcon } from "../../../icons/LocationPinIcon";
import { DetailTag } from "./DetailTag";


export const CardDetailTags = ({
  location,
  opportunityType,
  cause,
  duration,
  variant = "full",
  showLines = true,
}) => {

  return (
    <span className={`CardDetailTags root ${showLines ? "showLines" : ""}`}>
      <DetailTag
        icon={<ClockIcon />}
        label={`${duration} month${duration !== 1 && 's'}`}/>
      <DetailTag
        icon={<LocationPinIcon />}
        label={location}/>
      {variant === "full" &&
        <>
        {opportunityType && <DetailTag
          icon={<ComputerMonitorIcon/>}
          label={opportunityType}/>}
        {cause && <DetailTag
          icon={<HeartIcon/>}
            label={cause} />}
        </>
        }
    </span>
  );
};
