/** ButtonLink documentation
 */
import { Typography } from "../../Typography";
import { VARIANTS } from "./../Button";

import "./../styles.css";

export const ButtonLink = ({ label, href, variant, className, iconLeft }) => {
  return (
    <a
      className={`root Button ButtonLink ${VARIANTS[variant] ?? VARIANTS.default} ${className ? className : ""}`}
      href={href}
    >
      {iconLeft && iconLeft}
      <Typography text={label} variant="btnMd" />
    </a>
  );
};
