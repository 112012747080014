// import { Button } from "../../sharedComponents/Button";
import { ButtonLink } from "../../sharedComponents/Button/ButtonLink/ButtonLink";
import "./Search.css"; // Import CSS file for custom styles
import { LocationPinIcon } from "../../../icons/LocationPinIcon";
import { InputLabel } from "../../sharedComponents/TextInput/InputLabel";
import { useState } from "react";
import { APIProvider } from '@vis.gl/react-google-maps';
import { PlaceAutocompleteClassic } from "./AutoComplete";

export function Search() {

  const [location, setLocation] = useState({ lat: null, lng: null, name: null })

  return (
    <div className="Search root container">
      <div className="form-container">
        <form action="">
          <div className="input-label-group">
            <label>
              <InputLabel text={"Location"} />
              <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
                <span className="location-with-pin">
                  <PlaceAutocompleteClassic setLocation={setLocation} />
                  <LocationPinIcon
                    color="#A0ABC0" // css variable muted-purple-gray
                  />
                </span>
              </APIProvider>
            </label>
          </div>

          <div className="input-label-group">
            <label>
              <InputLabel text={"Tell us the job title you are looking for"} />

              <span className="search-with-button">
                <input
                  type="text"
                  name="search-term"
                  id="search-terms"
                  placeholder="What role are you interested in?"
                />
                {/* <Button
                label="Search for opportunities"
                onClick={() => {
                  // insert function to search
                  }}
                  /> */}
                {/* //!! Temporary: Use Button when Search is enabled */}

                <ButtonLink
                  label="Search for opportunities"
                  // TODO: append querystring to href
                  href={"/opportunities"}
                  variant={"default"}
                  onClick={() => {
                    // insert function to search
                  }}
                />
              </span>
            </label>
          </div>
        </form>
      </div>
    </div>
  );
}