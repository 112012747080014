// default color is red
export const BlockedIcon = ({ color = "#ff0000" }) => {
  return (
    <svg
      width="16"
      height="16"
      className="root BlockedIcon"
      focusable="false"
      aria-hidden="true"
      viewBox="0 0 24 24"
      datatestid="DoNotDisturbAltOutlinedIcon"
      tabIndex="-1"
      title="DoNotDisturbAltOutlined"
      fill={color}
    >
      <path
        d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2M4 12c0-4.4 3.6-8 8-8 1.8 0 3.5.6 4.9 1.7L5.7 16.9C4.6 15.5 4 13.8 4 12m8 8c-1.8 0-3.5-.6-4.9-1.7L18.3 7.1C19.4 8.5 20 10.2 20 12c0 4.4-3.6 8-8 8"
        fill={color}
      ></path>
    </svg>
  );
};
