import { Infographic1 } from "./Infographic1"
import { Infographic2 } from "./Infographic2"
import { Infographic3 } from "./Infographic3"

export const content = [
  {
    bigText: "50+",
    smallText: "volunteers made impact through us",
    img: Infographic3
  },
  {
    bigText: "14",
    smallText: "languages that we speak",
    img:Infographic2
  },
  {
    bigText: "43+",
    smallText: "projects delivered",
    img: Infographic1
  },
]