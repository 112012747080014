import React, { useRef, useEffect, useState } from 'react';
import { useMapsLibrary } from '@vis.gl/react-google-maps';

// This is an example of the classic "Place Autocomplete" widget.
// https://developers.google.com/maps/documentation/javascript/place-autocomplete
export const PlaceAutocompleteClassic = ({ setLocation }) => {
    const [placeAutocomplete, setPlaceAutocomplete] = useState(null);
    const inputRef = useRef(null);
    const places = useMapsLibrary('places');

    useEffect(() => {
        if (!places || !inputRef.current) return;

        const options = {
            fields: ['geometry', 'name', 'formatted_address']
        };

        setPlaceAutocomplete(new places.Autocomplete(inputRef.current, options));
    }, [places]);

    useEffect(() => {
        if (!placeAutocomplete) return;

        placeAutocomplete.addListener('place_changed', () => {
            const place = placeAutocomplete.getPlace()
            const lat = place?.geometry?.location?.lat()
            const lng = place?.geometry?.location?.lng()
            const name = place.formatted_address
            setLocation({ lat, lng, name })
        });

    }, [setLocation, placeAutocomplete]);

    return (
        <input
            className="location-input"
            type="text"
            name="search-term"
            id="search-term-location"
            placeholder="example: San Francisco, CA "
            ref={inputRef}
        />
    );
};
