/** InfoGraphic documentation
 */
import { Typography } from "../../../sharedComponents/Typography";
import "./styles.css";


export const Infographic = ({
  bigText,
  smallText,
  img
}) => {

  return (
    <figure className="Infographic root">
      <span className="text">
        <Typography
          text={bigText}
          variant="hXL"
          color="orange"/>
        <Typography
          text={smallText}
          variant="bXL"
          color="black"/>
        </span>

      {img()}
  </figure>);
};
