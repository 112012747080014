import { opportunities } from "./opportunities";
import {causes} from "./causes"
import {stats} from "./statistics"
import {favorites} from "./favorites"
import {testimonials} from "./testimonials"

export const API = {
  opportunities,
  causes,
  stats,
  favorites,
  testimonials
}