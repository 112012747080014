import { useState, useEffect } from "react";
import "./Testimonials.css";
import { TestimonialCard } from "./TestimonialCard";
import { Button } from "../../sharedComponents/Button";
import { Typography } from "../../sharedComponents/Typography";
import { API } from "../../../api/index";
import { Loader } from "../../sharedComponents/Loader";
import { testimonials as mocks } from "../../../constants/mockup_testimonials";
import { LOGIN_SIGN_UP_MODAL_MODES } from "../../LoginSignUpModal";

export function Testimonials({
  setIsLoginSignUpOpen,
  setLoginSignUpModalMode,
}) {
  const [testimonials, setTestimonials] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (testimonials === undefined) {
      const getTestimonials = async () => {
        try {
          const testimonialsResult = await API.testimonials.getTestimonials();

          // check if result is not an error, BackEnd is currently sending errors that APIHelper does not recognize as errors 08/06/2024
          if (Array.isArray(testimonialsResult) && testimonialsResult.length) {
            setTestimonials(testimonialsResult);
          } else {
            throw new Error(testimonialsResult)
          }
        } catch (error) {
          // use mocked data when there is an error
          console.warn("An error occurred when getting testimonials, loading static testimonials")
          setTestimonials(mocks);
        }
        setIsLoading(false);
      };

      getTestimonials();
    }
  }, [testimonials]);

  return (
    <section className="root Testimonials">
      <Typography
        variant="hMd"
        text="What our clients and volunteers say?"
        color="purple-header"
      />
      <div className="testimonial-card-container">
        {testimonials?.length ? (
          testimonials.slice(0, 3).map((testimonial) => {
            return (
              <TestimonialCard
                name={testimonial.author}
                testimonial={testimonial.testimonial}
                key={
                  testimonial.id ??
                  `${testimonial.author}-${testimonial.testimonial.substring(0,6)}`
                }
              />
            );
          })
        ) : isLoading ? (
          <Loader />
        ) : null}
      </div>
      <Button
        onClick={() => {
          setIsLoginSignUpOpen(true);
          setLoginSignUpModalMode(LOGIN_SIGN_UP_MODAL_MODES.volunteerSignUp);
        }}
        label="Become part of change"
        variant="default"
      />
    </section>
  );
}
