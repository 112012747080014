import CausesModal from "./CausesModal";
import "./SearchNavBar.css";
import { useState, useEffect } from "react";
import { API } from "../../api";

export const SearchNavBar = ({setOpportunities}) => {
  const [causes, setCauses] = useState([]);
  const [isCausesPopUpOpen, setIsCausesPopUpOpen] = useState(false);
  // -1 so that when all causes are unchecked, it goes back to the useEffect and shows all opps again
  const [checkedCauses, setCheckedCauses] = useState([-1]);

  // * KEEP, templated to implement Skills filter
  // const [skills, setSkills] = useState([]);
  // const [isSkillsPopUpOpen, setIsSkillsPopUpOpen] = useState(false);
  // const [checkedSkills, setCheckedSkills] = useState([]);

  useEffect(() => {
    if (causes.length === 0) {
      // API call to GET Causes
      const fetchCauses = async () => {
        const data = await API.causes.getCauses();
        setCauses(data);
      };

      fetchCauses();
    }
  }, [causes]);

  useEffect(() => {
    if (checkedCauses.length !== 0) {
      // API call to filter by skills/causes
      const fetchOppsByCauses = async () => {
        const data = await API.opportunities.getOpportunitiesByCauses({
          // skill_ids: checkedSkills,
          cause_ids: checkedCauses,
        });
        setOpportunities(data)
      };

      fetchOppsByCauses();
    }
  }, [checkedCauses, setOpportunities ]); //checkedSkills

  return (
    <div className="search-nav-bar-wrapper">
      <div className="search-nav-bar">
        <button className="filter-option">Search All</button>
        <button
          className="filter-option"
          onClick={() => setIsCausesPopUpOpen(!isCausesPopUpOpen)}
          style={{ color: isCausesPopUpOpen ? "#FFA400" : "#514A68" }}
        >
          Causes
        </button>
        <button className="filter-option">Skills</button>
        <button className="filter-option">Location</button>
        <button className="filter-option">Clear all filters</button>
      </div>
      {isCausesPopUpOpen && (
        <CausesModal
          isOpen={isCausesPopUpOpen}
          causes={causes}
          checkedCauses={checkedCauses}
          setCheckedCauses={setCheckedCauses}
        />
      )}
    </div>
  );
};
