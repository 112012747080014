export const FacebookIcon = ({ color = "white" }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 1C13.4087 1 11.8826 1.63214 10.7574 2.75736C9.63214 3.88258 9 5.4087 9 7V9H7C6.44772 9 6 9.44772 6 10V14C6 14.5523 6.44772 15 7 15H9V22C9 22.5523 9.44772 23 10 23H14C14.5523 23 15 22.5523 15 22V15H17C17.4589 15 17.8589 14.6877 17.9701 14.2425L18.9701 10.2425C19.0448 9.94379 18.9777 9.6273 18.7882 9.38459C18.5987 9.14187 18.3079 9 18 9H15V7H18C18.5523 7 19 6.55228 19 6V2C19 1.44772 18.5523 1 18 1H15ZM12.1716 4.17157C12.9217 3.42143 13.9391 3 15 3H17V5H15C14.4696 5 13.9609 5.21071 13.5858 5.58579C13.2107 5.96086 13 6.46957 13 7V10C13 10.5523 13.4477 11 14 11H16.7192L16.2192 13H14C13.4477 13 13 13.4477 13 14V21H11V14C11 13.4477 10.5523 13 10 13H8V11H10C10.5523 11 11 10.5523 11 10V7C11 5.93913 11.4214 4.92172 12.1716 4.17157Z"
        fill={color}
      />
    </svg>
  );
};
