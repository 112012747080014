/** SignUpVolunteerForm documentation
 */
import { useState } from "react";

import "./styles.css";
import { SignUpHeader } from "../SignUpHeader/SignUpHeader";
import { Page1, Page2, Page3 } from "./pages";

const INITIAL_FORM_STATE = {
  email: "",
  password: "",
  passwordConfirm: "",
  firstName: "",
  lastName: "",
  location: "",
  linkedInUrl: "",
  portfolioUrl: "",
  title: "",
  skills: [],
  elevatorPitch: "",

  // type will be "File": https://developer.mozilla.org/en-US/docs/Web/API/File_API/Using_files_from_web_applications
  resume: undefined,
};

const INITIAL_ERROR_STATE = { password: "" };

const STEP_CONTENT = [
  {
    headline: "Welcome to CPI board!",
    instruction: "Create volunteer account",
  },
  {
    headline: "Fill in profile information",
    instruction: "Tell us about yourself",
  },
  {
    headline: "Final Step",
    instruction: "Share with us you professional highlights",
  },
];

export const SignUpVolunteerForm = ({ setModalMode }) => {
  const [form, setForm] = useState(INITIAL_FORM_STATE);
  const [formError, setFormError] = useState(INITIAL_ERROR_STATE);
  const [step, setStep] = useState(1);

  const handleChange = (event) => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const handleValidation = (event, formError,  valid) => {
    if (formError && valid(event.target.value)) {
      setFormError(INITIAL_ERROR_STATE)
    }
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  return (
    <div className="root SignUpVolunteerForm">
      <SignUpHeader
        headline={STEP_CONTENT[step - 1].headline}
        step={step}
        instruction={STEP_CONTENT[step - 1].instruction}
      />

      {step === 1 ? (
        <Page1
          form={form}
          handleChange={handleChange}
          handleValidation={handleValidation}
          setFormError={setFormError}
          setStep={setStep}
          formError={formError}
          setModalMode={setModalMode}
        />
      ) : step === 2 ? (
        <Page2
          form={form}
          setForm={setForm}
          handleChange={handleChange}
          handleValidation={handleValidation}
          setFormError={setFormError}
          setStep={setStep}
          formError={formError}
        />
      ) : (
        <Page3
          form={form}
          setForm={setForm}
          handleChange={handleChange}
          handleValidation={handleValidation}
          setFormError={setFormError}
          setStep={setStep}
          formError={formError}
        />
      )}
    </div>
  );
};
