export const BASE_REQUEST = (method = "GET", headers) => {
  return {// *GET, POST, PUT, DELETE, etc.
    // method: "GET",
    method: "GET",

    // no-cors, cors, same-origin
    mode: "cors",

    // *default, no-cache, reload, force-cache, only-if-cached
    cache: "no-cache",

    // include, *same-origin, omit
    credentials: "same-origin",

    headers: {
      // 'Content-Type': 'application/x-www-form-urlencoded',
      "Content-Type": "application/json",
      ...headers
    },

    // manual, *follow, error
    redirect: "follow",

    // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url

    referrerPolicy: "no-referrer",
    // body data type must match "Content-Type" header
    // body: JSON.stringify(data),

  }
}
/**
templated from https://stackoverflow.com/a/56913019
Usage Example:
  componentDidMount() {
    let url = `http://localhost/project/api/getdata`;
    ApiHelper(url)
      .then(response => {
        console.log(response);
      });
  }
    */

export const ApiHelper = async (url, data = {}, method = 'GET') => {
  try {
    // let bearer = 'Bearer ' + localStorage.getItem('user_token');

    const response = await fetch(url, {  // Return promise
      method: method,
      withCredentials: true,
      // credentials: 'include',
      headers: {
        // 'Authorization': bearer,
        // 'X-FP-API-KEY': 'chaptoken',
        // 'Content-Type': 'application/json',
        // 'Content-Type': 'application/x-www-form-urlencoded',
        // 'mode': "no-cors",
        // 'Access-Control-Allow-Origin': '*'
      },
    })
    const result = await response.json()

    if (response.status.toString()[0] === "2") {
      // will not log in production
      if (process.env.NODE_ENV !== "production") { console.log(url, result) }
      return result
    } else {
      const { headers, status, statusText, type, url } = response
      throw new Error(JSON.stringify(result), { headers, status, statusText, type, url })
    }
  } catch (error) {
    console.error(`ERROR ${method} -> ${url}:`, error)
    return error
  }
}