import React, { useState } from "react";
import { NavBar } from "./sharedComponents/NavBar";
import { Routes, Route } from "react-router-dom";
import ForCompaniesPage from "../reactComponents/ForCompaniesPage";
import { VolunteerBoard } from "./homePage/VolunteerBoard";
import About from "../reactComponents/About";
import { Footer } from "./sharedComponents/Footer";
import Login from "../reactComponents/Login";
import JoinAsVolunteer from "../reactComponents/JoinAsVolunteer";
import { OpportunitiesPage } from "./OpportunitiesPage/OpportunitiesPage";
import { OpportunityDetailPage } from "./OpportunityDetailPage";
import {
  LOGIN_SIGN_UP_MODAL_MODES,
  LoginSignUpModal,
} from "./LoginSignUpModal";
import "@fontsource/poppins"; // Defaults to weight 400
import "@fontsource/poppins/600.css";
import "@fontsource/poppins/700.css";

export default function App() {
  const [isLoginSignUpOpen, setIsLoginSignUpOpen] = useState(false);
  const [loginSignUpModalMode, setLoginSignUpModalMode] = useState(
    LOGIN_SIGN_UP_MODAL_MODES.login
  );

  return (
    <>
      <NavBar
        setIsLoginSignUpOpen={setIsLoginSignUpOpen}
        setLoginSignUpModalMode={setLoginSignUpModalMode}
      />
      <LoginSignUpModal
        isLoginSignUpOpen={isLoginSignUpOpen}
        setIsLoginSignUpOpen={setIsLoginSignUpOpen}
        modalMode={loginSignUpModalMode}
        setModalMode={setLoginSignUpModalMode}
      />
      <Routes>
        <Route
          path="/"
          element={
            <VolunteerBoard
              setIsLoginSignUpOpen={setIsLoginSignUpOpen}
              setLoginSignUpModalMode={setLoginSignUpModalMode}
            />
          }
        />
        <Route path="/about" element={<About />} />
        <Route path="/for-companies" element={<ForCompaniesPage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/join-as-volunteer" element={<JoinAsVolunteer />} />
        <Route path="/opportunities" element={<OpportunitiesPage />} />
        <Route
          path="/companies/:company_id/opportunities/:id"
          element={<OpportunityDetailPage />}
        />
      </Routes>
      <Footer />
    </>
  );
}
