import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "bootstrap/dist/css/bootstrap.min.css";
import { useLocation } from "react-router-dom";
import "./Navbar.css";
import { Button } from "../Button";
import {VolunteerBoardLogoIcon} from "../../../icons/VolunteerBoardLogoIcon";
import { LOGIN_SIGN_UP_MODAL_MODES } from "../../LoginSignUpModal";
import { VARIANTS } from "../Button/Button";
import { Typography } from "../Typography/Typography";

export function NavbarNavigation({
  setIsLoginSignUpOpen,
  setLoginSignUpModalMode,
}) {
  const location = useLocation();
  return (
    <Navbar expand="lg" data-bs-theme="light" className="custom-navbar">
      <Container className="flex-children">
        <Navbar.Brand href="/">
          <VolunteerBoardLogoIcon />
        </Navbar.Brand>
        <Navbar.Toggle className="navbar-dark" aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto d-flex justify-content-center align-items-center flex-wrap w-100">
            <Nav.Link
              href="/"
              className={location.pathname === "/" ? "nav-link-active" : ""}
            >
              <Typography
                text={"For Volunteers"}
                variant="btnMd"
                color="white"
              />
            </Nav.Link>
            <Nav.Link
              href="/for-companies"
              className={
                location.pathname === "/for-companies" ? "nav-link-active" : ""
              }
            >
              <Typography
                text={"For Companies"}
                variant="btnMd"
                color="white"
              />
            </Nav.Link>
            <Nav.Link
              href="/about"
              className={
                location.pathname === "/about" ? "nav-link-active" : ""
              }
            >
              <Typography text={"About CPI"} variant="btnMd" color="white" />
            </Nav.Link>
          </Nav>
          <Nav className="ms-auto d-flex align-items-center button-container">
            <Button
              /**
               * TODO: when conditionally, Button.label should be "Login" or "Logout".
               * What we use to determine this will also determine if we we see LoginSignUp
               * or Logout (no Design as of 08/06/2024).
               *
               * We may be able to useContext for this as Redux might be overkill for this
               * size of a project. There is not much global state to store.
               *
               * Maybe Logout can be the simple action of hitting the Button for now.
               * logout() will need to make the API call to Backend and use LocalStorageService to remove the token from localStorage
               */
              label="Login"
              variant={VARIANTS.defaultDark}
              onClick={() => {
                setIsLoginSignUpOpen(true);
              }}
            />
            <Button
              label="Join as a volunteer"
              onClick={() => {
                setIsLoginSignUpOpen(true);
                console.log(LOGIN_SIGN_UP_MODAL_MODES.volunteerSignUp);
                setLoginSignUpModalMode(
                  LOGIN_SIGN_UP_MODAL_MODES.volunteerSignUp
                );
              }}
            />
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
