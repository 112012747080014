export const GlobeIcon = ({ color="#FFA400"}) => {


  return (
    <svg
      width="23"
      height="22"
      viewBox="0 0 23 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5 22C17.5751 22 22.5 17.0751 22.5 11C22.5 4.92487 17.5751 0 11.5 0C5.42487 0 0.5 4.92487 0.5 11C0.5 17.0751 5.42487 22 11.5 22ZM9.22571 2.28983C5.66881 3.21597 2.96801 6.26324 2.55493 10H6.55009C6.7743 7.23681 7.6991 4.58442 9.22571 2.28983ZM13.7743 2.28983C15.3009 4.58442 16.2257 7.23681 16.4499 10H20.4451C20.032 6.26324 17.3312 3.21597 13.7743 2.28983ZM14.4424 10C14.1912 7.28683 13.1697 4.70193 11.5 2.5508C9.83032 4.70193 8.80879 7.28683 8.55759 10H14.4424ZM8.55759 12H14.4424C14.1912 14.7132 13.1697 17.2981 11.5 19.4492C9.83032 17.2981 8.80879 14.7132 8.55759 12ZM6.55009 12H2.55493C2.96801 15.7368 5.66881 18.784 9.22571 19.7102C7.6991 17.4156 6.7743 14.7632 6.55009 12ZM13.7743 19.7102C15.3009 17.4156 16.2257 14.7632 16.4499 12H20.4451C20.032 15.7368 17.3312 18.784 13.7743 19.7102Z" fill={color} />
    </svg>
  )
}